import { 
    OFFICERS_DATA, 
    OFFICER_RECORD, 
    UNIT_OFFICERS_DATA, 
    UPDATE_OFFICER_START, 
    DELETE_OFFICER_START, 
    DELETE_OFFICER_SUCCESS, 
    SELECT_OFFICER,
    FILTERED_OFFICERS_DATA,
    DASHBOARD_FILTERS_CLEAR_SELECTEDOFFICER,
    OFFICER_AFFECTED_TASK_DATA_START,
    OFFICER_AFFECTED_TASK_DATA_SUCCESS,
    DELETE_OFFICER_TASK_START,
    DELETE_OFFICER_TASK_SUCCESS,
    CREATE_OFFICER_TASK_START,
    CREATE_OFFICER_TASK_SUCCESS,
    OFFICERS_AFFECTED_TASK_FOR_UNIT_DATA_START,
    OFFICERS_AFFECTED_TASK_FOR_UNIT_DATA_SUCCESS,
    UPDATE_OFFICER_TASKS_START,
    UPDATE_OFFICER_TASKS_SUCCESS
} from "../constants/ActionTypes";

const INIT_STATE = {
  officers: [],
  filteredOfficers: [],
  taskOfficers: [],
  officerId: 0,
  officersLoaded: false,
  updatingOfficer: false,
  selectedOfficer: null,
  taskOfficersLoaded: false,
  affectedTasks: [],
  affectedTasksLoaded:false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case OFFICERS_DATA: {
      return {
        ...state,
        officers: action.payload === undefined ? [] : action.payload,
        filteredOfficers: action.payload === undefined ? [] : action.payload,
        officersLoaded: true,
        affectedTasks:[]
      };
    }

    case UNIT_OFFICERS_DATA: {
      return {
        ...state,
        ['officers' + action.unitId]: action.payload === undefined ? [] : action.payload,
        ['activeOfficers' + action.unitId]: action.payload === undefined ? [] : action.payload.filter(n => n.isActive),
        taskOfficers: action.payload === undefined ? [] : action.payload,
        taskOfficersLoaded: true,
        ['officersLoaded'+action.unitId]: true
      };
    }

    case OFFICER_RECORD: {
      return {
        ...state,
        userId: action.payload.userId,
        officerId: action.payload.officerId,
        updatingOfficer: false
      };
    }

    case DELETE_OFFICER_SUCCESS: {
      return {
        ...state,
        userId: 0,
        officerId: 0,
        updatingOfficer: false
      };
    }

    case UPDATE_OFFICER_START:
    case DELETE_OFFICER_START: {
      return {
        ...state,
        updatingOfficer: true
      };
    }

    case SELECT_OFFICER: {
      return {
        ...state,
        selectedOfficer: action.payload,
        affectedTasks:[]
      };
    }

    case FILTERED_OFFICERS_DATA: {
      return {
        ...state,
        filteredOfficers: action.payload === undefined ? [] : action.payload,
      };
    }

    case DASHBOARD_FILTERS_CLEAR_SELECTEDOFFICER: {
      return {
        ...state,
        taskOfficers: []
      };
    }

    case OFFICER_AFFECTED_TASK_DATA_START: {
      return {
        ...state,
        affectedTasksLoaded: false
      }
    }

    case OFFICER_AFFECTED_TASK_DATA_SUCCESS: {
      return {
        ...state,
        affectedTasks: action.payload === undefined ? [] : action.payload,
        affectedTasksLoaded: true
      }
    }

    case DELETE_OFFICER_TASK_START: {
      return {
        ...state,
        affectedTasksLoaded:false
      }
    }

    case DELETE_OFFICER_TASK_SUCCESS: {
      return {
        ...state      
      }
    }

    case CREATE_OFFICER_TASK_START: {
      return {
        ...state,
        affectedTasksLoaded:false
      }
    }

    case CREATE_OFFICER_TASK_SUCCESS: {
      return {
        ...state
      }
    }

    case OFFICERS_AFFECTED_TASK_FOR_UNIT_DATA_START: {
      return {
        ...state,
        ['officersTasks' + action.unitId] : [],
        ['officersTasksLoaded' + action.unitId] : false
      }
    }

    case OFFICERS_AFFECTED_TASK_FOR_UNIT_DATA_SUCCESS: {
      return {
        ...state,
        ['officersTasks' + action.unitId] : action.payload === undefined ? [] : action.payload,
        ['officersTasksLoaded' + action.unitId] : true
      }
    }

    case UPDATE_OFFICER_TASKS_START: {
      return {
        ...state,
        ['officersTasksLoaded' + action.unitId] : false,
        ['updatingOfficerAffectedTasks' + action.unitId] : true
      }
    }

    case UPDATE_OFFICER_TASKS_SUCCESS: {
      return {
        ...state,
        ['updatingOfficerAffectedTasks' + action.unitId] : false
      }
    }

    default:
      return state;
  }
}
