import {
  FETCH_DASHBOARD_TASKS_TIMELINE_START,
  FETCH_DASHBOARD_TASKS_TIMELINE_DATA,
  FETCH_DASHBOARD_TASKS_TIMELINE_FAILED,
  FETCH_DASHBOARD_TASKS_PIVOTDATA_START,
  FETCH_DASHBOARD_TASKS_PIVOTDATA_DATA,
  FETCH_DASHBOARD_TASKS_PIVOTDATA_FAILED,
  FETCH_DASHBOARD_TASKS_PIVOTDATA_FILTEREDDATA,
  FETCH_DASHBOARD_TASKDETAILSDATA_START,
  FETCH_DASHBOARD_TASKDETAILSDATA_DATA,
  FETCH_DASHBOARD_TASKDETAILSDATA_FAILED,
  FETCH_DASHBOARD_TASKDETAILSDATA_FILTEREDDATA,
  FETCH_DASHBOARD_TASKDETAILSDETAILDATA_START,
  FETCH_DASHBOARD_TASKDETAILSDETAILDATA_DATA,
  FETCH_DASHBOARD_TASKDETAILSDETAILDATA_FAILED,
  FETCH_DASHBOARD_TASKDETAILSDETAILDATA_FILTEREDDATA,
  FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_START,
  FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_DATA,
  FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_FAILED,
  FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_FILTEREDDATA,
  FETCH_DASHBOARD_AVERAGE_PERFORMANCEDATA_START,
  FETCH_DASHBOARD_AVERAGE_PERFORMANCEDATA_DATA,
  FETCH_DASHBOARD_AVERAGE_PERFORMANCEDATA_FAILED,
  FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_START,
  FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_DATA,
  FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_FAILED,
  FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_FILTEREDDATA,  
  FETCH_DASHBOARD_TASKS_TABLEDATA_START,
  FETCH_DASHBOARD_TASKS_TABLEDATA_DATA,
  FETCH_DASHBOARD_TASKS_TABLEDATA_FILTEREDDATA,
  FETCH_DASHBOARD_TASKS_TABLEDATA_FAILED,
  FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_START,
  FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_DATA,
  FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_FILTEREDDATA,
  FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_FAILED,
  FETCH_DASHBOARD_TASKSANDON_TABLEDATA_START,
  FETCH_DASHBOARD_TASKSANDON_TABLEDATA_DATA,
  FETCH_DASHBOARD_TASKSANDON_TABLEDATA_FILTEREDDATA,
  FETCH_DASHBOARD_TASKSANDON_TABLEDATA_FAILED,
  FETCH_DASHBOARD_TASKSHELP_TABLEDATA_START,
  FETCH_DASHBOARD_TASKSHELP_TABLEDATA_DATA,
  FETCH_DASHBOARD_TASKSHELP_TABLEDATA_FILTEREDDATA,
  FETCH_DASHBOARD_TASKSHELP_TABLEDATA_FAILED,  
  SELECT_OFFICERTASK,
  OFFICERTASK_RECORD,
  UPDATE_OFFICERTASK_START,
  DELETE_OFFICERTASK_START,
  DELETE_OFFICERTASK_SUCCESS,
  FETCH_DASHBOARD_OFFICERTASK_TIMELINE_START,
  FETCH_DASHBOARD_OFFICERTASK_TIMELINE_DATA,
  FETCH_DASHBOARD_OFFICERTASK_TIMELINE_FAILED,
  FETCH_DASHBOARD_EFFICIENCY_START,
  FETCH_DASHBOARD_EFFICIENCY_DATA,
  FETCH_DASHBOARD_EFFICIENCY_FAILED,
  FETCH_DASHBOARD_TASKTIMEPERIOD_START,
  FETCH_DASHBOARD_TASKTIMEPERIOD_DATA,
  FETCH_DASHBOARD_TASKTIMEPERIOD_FAILED,
  FETCH_DASHBOARD_TASKTOTALTIMEPERIOD_START,
  FETCH_DASHBOARD_TASKTOTALTIMEPERIOD_DATA,
  FETCH_DASHBOARD_TASKTOTALTIMEPERIOD_FAILED,
  FETCH_DASHBOARD_TASKCOUNTPERIOD_START,
  FETCH_DASHBOARD_TASKCOUNTPERIOD_DATA,
  FETCH_DASHBOARD_TASKCOUNTPERIOD_FAILED, 
  FETCH_DASHBOARD_ISSUESTIMEPERIOD_START,
  FETCH_DASHBOARD_ISSUESTIMEPERIOD_DATA,
  FETCH_DASHBOARD_ISSUESTIMEPERIOD_FAILED,
  FETCH_DASHBOARD_ISSUESCOUNTPERIOD_START,
  FETCH_DASHBOARD_ISSUESCOUNTPERIOD_DATA,
  FETCH_DASHBOARD_ISSUESCOUNTPERIOD_FAILED,
  FETCH_DASHBOARD_COACHINGSTIMEPERIOD_START,
  FETCH_DASHBOARD_COACHINGSTIMEPERIOD_DATA,
  FETCH_DASHBOARD_COACHINGSTIMEPERIOD_FAILED,
  FETCH_DASHBOARD_COACHINGSCOUNTPERIOD_START,
  FETCH_DASHBOARD_COACHINGSCOUNTPERIOD_DATA,
  FETCH_DASHBOARD_COACHINGSCOUNTPERIOD_FAILED,
  FETCH_DASHBOARD_TEAMEFFICIENCY_START,
  FETCH_DASHBOARD_TEAMEFFICIENCY_DATA,
  FETCH_DASHBOARD_TEAMEFFICIENCY_FAILED,
  FETCH_DASHBOARD_TEAMEFFICIENCYOFFICERS_START,
  FETCH_DASHBOARD_TEAMEFFICIENCYOFFICERS_DATA,
  FETCH_DASHBOARD_TEAMEFFICIENCYOFFICERS_FAILED,
  FETCH_DASHBOARD_DISTRIBUTION_START,
  FETCH_DASHBOARD_DISTRIBUTION_DATA,
  FETCH_DASHBOARD_DISTRIBUTION_FAILED,
  FETCH_DASHBOARD_DISTRIBUTION_ANDON_START,
  FETCH_DASHBOARD_DISTRIBUTION_ANDON_DATA,
  FETCH_DASHBOARD_DISTRIBUTION_ANDON_FAILED,
  FETCH_DASHBOARD_DISTRIBUTION_HELP_START,
  FETCH_DASHBOARD_DISTRIBUTION_HELP_DATA,
  FETCH_DASHBOARD_DISTRIBUTION_HELP_FAILED,
  FETCH_DASHBOARD_DISTRIBUTION_COACHINGS_START,
  FETCH_DASHBOARD_DISTRIBUTION_COACHINGS_DATA,
  FETCH_DASHBOARD_DISTRIBUTION_COACHINGS_SUCCESS,
  FETCH_DASHBOARD_DISTRIBUTION_ISSUES_START,
  FETCH_DASHBOARD_DISTRIBUTION_ISSUES_SUCCESS,
  FETCH_DASHBOARD_DISTRIBUTION_ISSUES_DATA,
  FETCH_DASHBOARD_ISSUES_START,
  FETCH_DASHBOARD_ISSUES_SUCCESS,
  FETCH_DASHBOARD_ISSUES_DATA,
  FETCH_DASHBOARD_ISSUES_REAL_TIME_DATA,
  FETCH_DASHBOARD_ISSUES_FAILED,
  FETCH_DASHBOARD_COACHINGS_START,
  FETCH_DASHBOARD_COACHINGS_SUCCESS,
  FETCH_DASHBOARD_COACHINGS_DATA,
  FETCH_DASHBOARD_COACHINGS_REAL_TIME_DATA,
  FETCH_DASHBOARD_COACHINGS_FAILED,
  FETCH_DASHBOARD_TASKS_START,
  FETCH_DASHBOARD_TASKS_SUCCESS,
  FETCH_DASHBOARD_TASKS_REAL_TIME_DATA,
  DASHBOARD_FILTERS_SELECTEDCOMPANY,
  DASHBOARD_FILTERS_CLEAR_SELECTEDCOMPANY,
  DASHBOARD_FILTERS_SELECTEDUNIT,
  DASHBOARD_FILTERS_SELECTEDOFFICER,
  DASHBOARD_FILTERS_SELECTEDTASK,
  DASHBOARD_FILTERS_SELECTEDDATEFROM,
  DASHBOARD_FILTERS_SELECTEDDATETO,
  DASHBOARD_FILTERS_SHOWVALUE,
  DASHBOARD_FILTERS_SELECTEDVIEWTYPE,
  DASHBOARD_FILTERS_SELECTEDISSUE,
  DASHBOARD_FILTERS_SELECTEDCOACHING,
  DASHBOARD_FILTERS_SELECTEDLIMITTYPE,
  DASHBOARD_FILTERS_SHOW_ANDON,
  FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_START,
  FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_DATA,
  FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_FILTEREDDATA,
  FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_FAILED,
  RESET_UPDATE_SUCCESS,
  UPDATE_OFFICERTASKSTIMELINE_SUCCESS,
  UPDATE_OFFICERTASK_SUCCESS,
  UPDATE_OFFICERTASKSTIMELINE_START,
  SPLIT_OFFICERTASKID,
  SPLIT_OFFICERTASK_SUCCESS,
  SPLIT_OFFICERTASK_START,  
} from "../constants/ActionTypes";

const INIT_STATE = {
  timeLines: [],
  timeLineLoading: false,
  timeLineLoaded: false,
  pivotData: [],
  pivotFilteredData: [],
  pivotDataLoading: false,
  pivotDataLoaded: false,
  taskDetailsData: [],
  taskDetailsFilteredData: [],
  taskDetailsDataLoading: false,
  taskDetailsDataLoaded: false,
  taskDetailsDetailData: [],
  taskDetailsDetailFilteredData: [],
  taskDetailsDetailDataLoading: false,
  taskDetailsDetailDataLoaded: false,
  taskDetailsDetailDateData: [],
  taskDetailsDetailDateFilteredData: [],
  taskDetailsDetailDateDataLoading: false,
  taskDetailsDetailDateDataLoaded: false,
  performanceAverageData: { 'best': [], 'worst': [], 'unit': [], 'task': [], 'officer': [] },
  performanceAverageDataLoading: true,
  performanceAverageDataLoaded: false,
  standardizationData: { 'tasks': [], 'officer': [] },
  standardizationFilteredData: { 'tasks': [], 'officer': [] },
  standardizationDataLoading: false,
  standardizationDataLoaded: false,
  tableData: [],
  tableFilteredData: [],
  tableDataLoading: false,
  tableDataLoaded: false,
  overMaxTableData: [],
  overMaxTableFilteredData: [],
  overMaxTableDataLoading: false,
  overMaxTableDataLoaded: false,
  andonTableData: [],
  andonTableFilteredData: [],
  andonTableDataLoading: false,
  andonTableDataLoaded: false,  
  tasksHelpTableData: [],
  tasksHelpTableFilteredData: [],
  tasksHelpTableDataLoading: false,
  tasksHelpTableDataLoaded: false,  
  selectedOfficerTask: null,
  updatingOfficerTask: false,
  officerTaskTimeLines: [],
  officerTaskTimeLineLoading: false,
  officerTaskTimeLineLoaded: false,
  splittingOfficerTask: false,
  splitOfficerTaskId: null,
  efficiencyData: [],
  efficiencyLoading: false,
  efficiencyLoaded: false,
  taskTimePeriodData: { 'team': [], 'setting': [], 'officer': [] },
  taskTimePeriodLoading: false,
  taskTimePeriodLoaded: false,
  taskTotalTimePeriodData: { 'team': [], 'setting': [], 'officer': [] },
  taskTotalTimePeriodLoading: false,
  taskTotalTimePeriodLoaded: false,
  issuesTimePeriodData: { 'team': [], 'officer' : [] },
  issuesTimePeriodLoading: false,
  issuesTimePeriodLoaded: false,
  coachingsTimePeriodData: { 'team': [], 'officer' : [] },
  coachingsTimePeriodLoading: false,
  coachingsTimePeriodLoaded: false,
  taskCountPeriodData: { 'team': [], 'officer': [] },
  taskCountPeriodLoading: false,
  taskCountPeriodLoaded: false,
  issuesCountPeriodData: { 'team': [], 'officer' : [] },
  issuesCountPeriodLoading: false,
  issuesCountPeriodLoaded: false,
  coachingsCountPeriodData: { 'team': [], 'officer' : [] },
  coachingsCountPeriodLoading: false,
  coachingsCountPeriodLoaded: false,
  teamEfficiencyData: { 'best': [], 'worst': [], 'unit': [], 'task': [], 'officer': [] },
  teamEfficiencyLoading: true,
  teamEfficiencyLoaded: false,
  teamEfficiencyOfficersData: {},
  teamEfficiencyOfficersLoading: true,
  teamEfficiencyOfficersLoaded: false,
  distributionData: {'unitTime': [], 'unitCount': [], 'officerTime': [], 'officerCount': [] },
  distributionLoading: true,
  distributionLoaded: false,
  distributionAndonData: {'unitTime': [], 'unitCount': [], 'officerTime': [], 'officerCount': [] },
  distributionAndonLoading: true,
  distributionAndonLoaded: false,
  distributionHelpData: {'unitTime': [], 'unitCount': [], 'officerTime': [], 'officerCount': [] },
  distributionHelpLoading: true,
  distributionHelpLoaded: false,
  distributionCoachingData: {'unitTime': [], 'unitCount': [], 'officerTime': [], 'officerCount': [] },
  distributionCoachingLoading: true,
  distributionCoachingLoaded: false,
  issuesData: [],
  issuesRealtimeData: [],
  issuesDataLoading: false,
  issuesDataLoaded: false,
  issuesDistributionData: [],
  issuesDistributionDataLoading: false,
  issuesDistributionDataLoaded:false,
  coachingsDistributionData: [],
  coachingsDistributionDataLoading: false,
  coachingsDistributionDataLoaded:false,
  coachingsData: [],
  coachingsRealtimeData: [],
  coachingsDataLoading: false,
  coachingsDataLoaded: false,
  dashboardFiltersSelectedCompany: null,
  dashboardFiltersSelectedUnit: null,
  dashboardFiltersSelectedOfficer: null,
  dashboardFiltersSelectedTask: null,
  dashboardFiltersSelectedDateFrom: new Date(),
  dashboardFiltersSelectedDateTo: new Date(),
  dashboardFiltersShowValue: true,
  dashboardFiltersSelectedViewType: "1",
  dashboardFiltersSelectedIssue: null,
  dashboardFiltersSelectedCoaching: null,
  dashboardFiltersSelectedLimitType: null,
  dashboardFiltersShowAndon: false,
  tableLogsData: [],
  tableLogsFilteredData: [],
  tableLogsDataLoading: false,
  tableLogsDataLoaded: false,  
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case FETCH_DASHBOARD_TASKS_TIMELINE_START: {
      return {
        ...state,
        timeLines: [],
        timeLineLoading: true,
        timeLineLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKS_TIMELINE_DATA: {
      return {
        ...state,
        timeLines: action.payload === undefined ? [] : action.payload,
        timeLineLoading: false,
        timeLineLoaded: true
      };
    }

    case FETCH_DASHBOARD_TASKS_TIMELINE_FAILED: {
      return {
        ...state,
        timeLines: [],
        timeLineLoading: false,
        timeLineLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKS_PIVOTDATA_START: {
      return {
        ...state,
        pivotData: [],
        pivotFilteredData: [],
        pivotDataLoading: true,
        pivotDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKS_PIVOTDATA_DATA: {
      return {
        ...state,
        pivotData: action.payload === undefined ? [] : action.payload,
        pivotFilteredData: action.payload === undefined ? [] : action.payload,
        pivotDataLoading: false,
        pivotDataLoaded: true
      };
    }

    case FETCH_DASHBOARD_TASKS_PIVOTDATA_FAILED: {
      return {
        ...state,
        pivotData: [],
        pivotFilteredData: [],
        pivotDataLoading: false,
        pivotDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKS_PIVOTDATA_FILTEREDDATA: {
      return {
        ...state,
        pivotFilteredData: action.payload === undefined ? [] : action.payload,
      };
    }

    case FETCH_DASHBOARD_TASKS_TABLEDATA_START: {
      return {
        ...state,
        tableData: [],
        tableFilteredData: [],
        tableDataLoading: true,
        tableDataLoaded: false,
        updateSuccess: false
      };
    }

    case FETCH_DASHBOARD_TASKS_TABLEDATA_DATA: {
      return {
        ...state,
        tableData: action.payload === undefined ? [] : action.payload,
        tableFilteredData: action.payload === undefined ? [] : action.payload,
        tableDataLoading: false,
        tableDataLoaded: true
      };
    }

    case FETCH_DASHBOARD_TASKS_TABLEDATA_FAILED: {
      return {
        ...state,
        tableData: [],
        tableFilteredData: [],
        tableDataLoading: false,
        tableDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKS_TABLEDATA_FILTEREDDATA: {
      return {
        ...state,
        tableFilteredData: action.payload === undefined ? [] : action.payload,
      };
    }

    case FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_START: {
      return {
        ...state,
        overMaxTableData: [],
        overMaxTableFilteredData: [],
        overMaxTableDataLoading: true,
        overMaxTableDataLoaded: false,
        updateSuccess: false
      };
    }

    case FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_DATA: {
      return {
        ...state,
        overMaxTableData: action.payload === undefined ? [] : action.payload,
        overMaxTableFilteredData: action.payload === undefined ? [] : action.payload,
        overMaxTableDataLoading: false,
        overMaxTableDataLoaded: true
      };
    }

    case FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_FAILED: {
      return {
        ...state,
        overMaxTableData: [],
        overMaxTableFilteredData: [],
        overMaxTableDataLoading: false,
        overMaxTableDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_FILTEREDDATA: {
      return {
        ...state,
        overMaxTableFilteredData: action.payload === undefined ? [] : action.payload,
      };
    }

    case FETCH_DASHBOARD_TASKSANDON_TABLEDATA_START: {
      return {
        ...state,
        andonTableData: [],
        andonTableFilteredData: [],
        andonTableDataLoading: true,
        andonTableDataLoaded: false,
        updateSuccess: false
      };
    }

    case FETCH_DASHBOARD_TASKSANDON_TABLEDATA_DATA: {
      return {
        ...state,
        andonTableData: action.payload === undefined ? [] : action.payload,
        andonTableFilteredData: action.payload === undefined ? [] : action.payload,
        andonTableDataLoading: false,
        andonTableDataLoaded: true
      };
    }

    case FETCH_DASHBOARD_TASKSANDON_TABLEDATA_FAILED: {
      return {
        ...state,
        andonTableData: [],
        andonTableFilteredData: [],
        andonTableDataLoading: false,
        andonTableDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKSANDON_TABLEDATA_FILTEREDDATA: {
      return {
        ...state,
        andonTableFilteredData: action.payload === undefined ? [] : action.payload,
      };
    }    

    case FETCH_DASHBOARD_TASKSHELP_TABLEDATA_START: {
      return {
        ...state,
        tasksHelpTableData: [],
        tasksHelpTableFilteredData: [],
        tasksHelpTableDataLoading: true,
        tasksHelpTableDataLoaded: false,
        updateSuccess: false
      };
    }

    case FETCH_DASHBOARD_TASKSHELP_TABLEDATA_DATA: {
      return {
        ...state,
        tasksHelpTableData: action.payload === undefined ? [] : action.payload,
        tasksHelpTableFilteredData: action.payload === undefined ? [] : action.payload,
        tasksHelpTableDataLoading: false,
        tasksHelpTableDataLoaded: true
      };
    }

    case FETCH_DASHBOARD_TASKSHELP_TABLEDATA_FAILED: {
      return {
        ...state,
        tasksHelpTableData: [],
        tasksHelpTableFilteredData: [],
        tasksHelpTableDataLoading: false,
        tasksHelpTableDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKSHELP_TABLEDATA_FILTEREDDATA: {
      return {
        ...state,
        tasksHelpTableFilteredData: action.payload === undefined ? [] : action.payload,
      };
    }

    case SELECT_OFFICERTASK: {
      return {
        ...state,
        selectedOfficerTask: action.payload
      };
    }

    case OFFICERTASK_RECORD: {
      return {
        ...state,
        officerTaskId: action.payload.officerTaskId,
        updatingOfficerTask: false,
        updateSuccess: true,
      };
    }

    case DELETE_OFFICERTASK_SUCCESS: {
      return {
        ...state,
        updatingOfficerTask: false
      };
    }

    case UPDATE_OFFICERTASKSTIMELINE_START:
    case UPDATE_OFFICERTASK_START:
    case DELETE_OFFICERTASK_START: {
      return {
        ...state,
        updatingOfficerTask: true,
        updateSuccess: false,
      };
    }

    case UPDATE_OFFICERTASKSTIMELINE_SUCCESS:
    case UPDATE_OFFICERTASK_SUCCESS: {
      return {
        ...state,
        updatingOfficerTask: false,
        updateSuccess: true,
      };
    }

    case RESET_UPDATE_SUCCESS : {
      return {
        ...state,
        updateSuccess: false,
      };
    }

    case SPLIT_OFFICERTASK_START: {
      return {
        ...state,
        splittingOfficerTask: true,
      };
    }

    case SPLIT_OFFICERTASK_SUCCESS: {
      return {
        ...state,
        splittingOfficerTask: false,
      };
    }

    case SPLIT_OFFICERTASKID: {
      return {
        ...state,
        splitOfficerTaskId: action.payload,
        splittingOfficerTask: false,
      };
    }

    case FETCH_DASHBOARD_OFFICERTASK_TIMELINE_START: {
      return {
        ...state,
        officerTaskTimeLines: [],
        officerTaskTimeLineLoading: true,
        officerTaskTimeLineLoaded: false
      };
    }

    case FETCH_DASHBOARD_OFFICERTASK_TIMELINE_DATA: {
      return {
        ...state,
        officerTaskTimeLines: action.payload === undefined ? [] : action.payload,
        officerTaskTimeLineLoading: false,
        officerTaskTimeLineLoaded: true
      };
    }

    case FETCH_DASHBOARD_OFFICERTASK_TIMELINE_FAILED: {
      return {
        ...state,
        officerTaskTimeLines: [],
        officerTaskTimeLineLoading: false,
        officerTaskTimeLineLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKDETAILSDATA_START: {
      return {
        ...state,
        taskDetailsData: [],
        taskDetailsFilteredData: [],
        taskDetailsDataLoading: true,
        taskDetailsDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKDETAILSDATA_DATA: {
      return {
        ...state,
        taskDetailsData: action.payload === undefined ? [] : action.payload,
        taskDetailsFilteredData: action.payload === undefined ? [] : action.payload,
        taskDetailsDataLoading: false,
        taskDetailsDataLoaded: true
      };
    }

    case FETCH_DASHBOARD_TASKDETAILSDATA_FAILED: {
      return {
        ...state,
        taskDetailsData: [],
        taskDetailsFilteredData: [],
        taskDetailsDataLoading: false,
        taskDetailsDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKDETAILSDATA_FILTEREDDATA: {
      return {
        ...state,
        taskDetailsFilteredData: action.payload === undefined ? [] : action.payload,
      };
    }

    case FETCH_DASHBOARD_TASKDETAILSDETAILDATA_START: {
      return {
        ...state,
        taskDetailsDetailData: [],
        taskDetailsDetailFilteredData: [],
        taskDetailsDetailDataLoading: true,
        taskDetailsDetailDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKDETAILSDETAILDATA_DATA: {
      return {
        ...state,
        taskDetailsDetailData: action.payload === undefined ? [] : action.payload,
        taskDetailsDetailFilteredData: action.payload === undefined ? [] : action.payload,
        taskDetailsDetailDataLoading: false,
        taskDetailsDetailDataLoaded: true
      };
    }

    case FETCH_DASHBOARD_TASKDETAILSDETAILDATA_FAILED: {
      return {
        ...state,
        taskDetailsDetailData: [],
        taskDetailsDetailFilteredData: [],
        taskDetailsDetailDataLoading: false,
        taskDetailsDetailDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKDETAILSDETAILDATA_FILTEREDDATA: {
      return {
        ...state,
        taskDetailsDetailFilteredData: action.payload === undefined ? [] : action.payload,
      };
    }

    case FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_START: {
      return {
        ...state,
        taskDetailsDetailDateData: [],
        taskDetailsDetailDateFilteredData: [],
        taskDetailsDetailDateDataLoading: true,
        taskDetailsDetailDateDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_DATA: {
      return {
        ...state,
        taskDetailsDetailDateData: action.payload === undefined ? [] : action.payload,
        taskDetailsDetailDateFilteredData: action.payload === undefined ? [] : action.payload,
        taskDetailsDetailDateDataLoading: false,
        taskDetailsDetailDateDataLoaded: true
      };
    }

    case FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_FAILED: {
      return {
        ...state,
        taskDetailsDetailDateData: [],
        taskDetailsDetailDateFilteredData: [],
        taskDetailsDetailDateDataLoading: false,
        taskDetailsDetailDateDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_FILTEREDDATA: {
      return {
        ...state,
        taskDetailsDetailDateFilteredData: action.payload === undefined ? [] : action.payload,
      };
    }

    case FETCH_DASHBOARD_AVERAGE_PERFORMANCEDATA_START: {
      return {
        ...state,
        performanceAverageData: { 'best': [], 'worst': [], 'unit': [], 'task': [], 'officer': [] },
        performanceAverageDataLoading: true,
        performanceAverageDataLoaded: false   

      };
    }

    case FETCH_DASHBOARD_AVERAGE_PERFORMANCEDATA_DATA: {
      return {
        ...state,
        performanceAverageData: action.payload === undefined ? { 'best': [], 'worst': [], 'unit': [], 'task': [], 'officer': [] } : action.payload,
        performanceAverageDataLoading: false,
        performanceAverageDataLoaded: true
      };
    }

    case FETCH_DASHBOARD_AVERAGE_PERFORMANCEDATA_FAILED: {
      return {
        ...state,
        performanceAverageData: { 'best': [], 'worst': [], 'unit': [], 'task': [], 'officer': [] },
        performanceAverageDataLoading: false,
        performanceAverageDataLoaded: false        
      };
    }

    case FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_START: {
      return {
        ...state,
        standardizationData: { 'tasks': [], 'officer': [] },
        standardizationFilteredData: { 'tasks': [], 'officer': [] },
        standardizationDataLoading: true,
        standardizationDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_DATA: {
      return {
        ...state,
        standardizationData: action.payload === undefined ? { 'tasks': [], 'officer': [] } : action.payload,
        standardizationFilteredData: action.payload === undefined ? { 'tasks': [], 'officer': [] } : action.payload,
        standardizationDataLoading: false,
        standardizationDataLoaded: true
      };
    }

    case FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_FAILED: {
      return {
        ...state,
        standardizationData: { 'tasks': [], 'officer': [] },
        standardizationFilteredData: { 'tasks': [], 'officer': [] },
        standardizationDataLoading: false,
        standardizationDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_FILTEREDDATA: {
      return {
        ...state,
        standardizationFilteredData: action.payload === undefined ? [] : action.payload,
      };
    }

    case FETCH_DASHBOARD_EFFICIENCY_START: {
      return {
        ...state,
        efficiencyData: [],
        efficiencyLoading: true,
        efficiencyLoaded: false
      };
    }

    case FETCH_DASHBOARD_EFFICIENCY_DATA: {
      return {
        ...state,
        efficiencyData: action.payload === undefined ? [] : action.payload,
        efficiencyLoading: false,
        efficiencyLoaded: true
      };
    }

    case FETCH_DASHBOARD_EFFICIENCY_FAILED: {
      return {
        ...state,
        efficiencyData: [],
        efficiencyLoading: false,
        efficiencyLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKTIMEPERIOD_START: {
      return {
        ...state,
        taskTimePeriodData: {  'team': [], 'setting': [], 'officer' : [] },
        taskTimePeriodLoading: true,
        taskTimePeriodLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKTIMEPERIOD_DATA: {
      return {
        ...state,
        taskTimePeriodData: action.payload === undefined ? { 'team': [], 'setting': [], 'officer' : [] } : action.payload,
        taskTimePeriodLoading: false,
        taskTimePeriodLoaded: true
      };
    }

    case FETCH_DASHBOARD_TASKTIMEPERIOD_FAILED: {
      return {
        ...state,
        taskTimePeriodData: { 'team': [], 'setting': [], 'officer' : [] },
        taskTimePeriodLoading: false,
        taskTimePeriodLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKTOTALTIMEPERIOD_START: {
      return {
        ...state,
        taskTotalTimePeriodData: {  'team': [], 'setting': [], 'officer' : [] },
        taskTotalTimePeriodLoading: true,
        taskTotalTimePeriodLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKTOTALTIMEPERIOD_DATA: {
      return {
        ...state,
        taskTotalTimePeriodData: action.payload === undefined ? { 'team': [], 'setting': [], 'officer' : [] } : action.payload,
        taskTotalTimePeriodLoading: false,
        taskTotalTimePeriodLoaded: true
      };
    }

    case FETCH_DASHBOARD_TASKTOTALTIMEPERIOD_FAILED: {
      return {
        ...state,
        taskTotalTimePeriodData: { 'team': [], 'setting': [], 'officer' : [] },
        taskTotalTimePeriodLoading: false,
        taskTotalTimePeriodLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKCOUNTPERIOD_START: {
      return {
        ...state,
        taskCountPeriodData: { 'team': [], 'officer' : [] },
        taskCountPeriodLoading: true,
        taskCountPeriodLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKCOUNTPERIOD_DATA: {
      return {
        ...state,
        taskCountPeriodData: action.payload === undefined ? { 'team': [], 'officer' : [] } : action.payload,
        taskCountPeriodLoading: false,
        taskCountPeriodLoaded: true
      };
    }

    case FETCH_DASHBOARD_TASKCOUNTPERIOD_FAILED: {
      return {
        ...state,
        taskCountPeriodData: { 'team': [], 'officer' : [] },
        taskCountPeriodLoading: false,
        taskCountPeriodLoaded: false
      };
    }

    case FETCH_DASHBOARD_ISSUESTIMEPERIOD_START: {
      return {
        ...state,
        issuesTimePeriodData: {  'team': [], 'officer' : [] },
        issuesTimePeriodLoading: true,
        issuesTimePeriodLoaded: false
      };
    }

    case FETCH_DASHBOARD_ISSUESTIMEPERIOD_DATA: {
      return {
        ...state,
        issuesTimePeriodData: action.payload === undefined ? { 'team': [], 'officer' : [] } : action.payload,
        issuesTimePeriodLoading: false,
        issuesTimePeriodLoaded: true
      };
    }

    case FETCH_DASHBOARD_ISSUESTIMEPERIOD_FAILED: {
      return {
        ...state,
        issuesTimePeriodData: { 'team': [], 'officer' : [] },
        issuesTimePeriodLoading: false,
        issuesTimePeriodLoaded: false
      };
    }

    case FETCH_DASHBOARD_ISSUESCOUNTPERIOD_START: {
      return {
        ...state,
        issuesCountPeriodData: {  'team': [], 'officer' : [] },
        issuesCountPeriodLoading: true,
        issuesCountPeriodLoaded: false
      };
    }

    case FETCH_DASHBOARD_ISSUESCOUNTPERIOD_DATA: {
      return {
        ...state,
        issuesCountPeriodData: action.payload === undefined ? { 'team': [], 'officer' : [] } : action.payload,
        issuesCountPeriodLoading: false,
        issuesCountPeriodLoaded: true
      };
    }

    case FETCH_DASHBOARD_ISSUESCOUNTPERIOD_FAILED: {
      return {
        ...state,
        issuesCountPeriodData: { 'team': [], 'officer' : [] },
        issuesCountPeriodLoading: false,
        issuesCountPeriodLoaded: false
      };
    }

    case FETCH_DASHBOARD_COACHINGSTIMEPERIOD_START: {
      return {
        ...state,
        coachingsTimePeriodData: {  'team': [], 'officer' : [] },
        coachingsTimePeriodLoading: true,
        coachingsTimePeriodLoaded: false
      };
    }

    case FETCH_DASHBOARD_COACHINGSTIMEPERIOD_DATA: {
      return {
        ...state,
        coachingsTimePeriodData: action.payload === undefined ? { 'team': [], 'officer' : [] } : action.payload,
        coachingsTimePeriodLoading: false,
        coachingsTimePeriodLoaded: true
      };
    }

    case FETCH_DASHBOARD_COACHINGSTIMEPERIOD_FAILED: {
      return {
        ...state,
        coachingsTimePeriodData: { 'team': [], 'officer' : [] },
        coachingsTimePeriodLoading: false,
        coachingsTimePeriodLoaded: false
      };
    }

    case FETCH_DASHBOARD_COACHINGSCOUNTPERIOD_START: {
      return {
        ...state,
        coachingsCountPeriodData: {  'team': [], 'officer' : [] },
        coachingsCountPeriodLoading: true,
        coachingsCountPeriodLoaded: false
      };
    }

    case FETCH_DASHBOARD_COACHINGSCOUNTPERIOD_DATA: {
      return {
        ...state,
        coachingsCountPeriodData: action.payload === undefined ? { 'team': [], 'officer' : [] } : action.payload,
        coachingsCountPeriodLoading: false,
        coachingsCountPeriodLoaded: true
      };
    }

    case FETCH_DASHBOARD_COACHINGSCOUNTPERIOD_FAILED: {
      return {
        ...state,
        coachingsCountPeriodData: { 'team': [], 'officer' : [] },
        coachingsCountPeriodLoading: false,
        coachingsCountPeriodLoaded: false
      };
    }


    case FETCH_DASHBOARD_TEAMEFFICIENCY_START: {
      return {
        ...state,
        teamEfficiencyData: { 'best': [], 'worst': [], 'unit': [], 'task': [], 'officer': [] },
        teamEfficiencyLoading: true,
        teamEfficiencyLoaded: false        
      };
    }

    case FETCH_DASHBOARD_TEAMEFFICIENCY_DATA: {
      return {
        ...state,
        teamEfficiencyData: action.payload === undefined ? { 'best': [], 'worst': [], 'unit': [], 'task': [], 'officer': [] } : action.payload,
        teamEfficiencyLoading: false,
        teamEfficiencyLoaded: true
      };
    }

    case FETCH_DASHBOARD_TEAMEFFICIENCY_FAILED: {
      return {
        ...state,
        teamEfficiencyData: { 'best': [], 'worst': [], 'unit': [], 'task': [], 'officer': [] },
        teamEfficiencyLoading: false,
        teamEfficiencyLoaded: false
      };
    }

    case FETCH_DASHBOARD_TEAMEFFICIENCYOFFICERS_START: {
      return {
        ...state,
        teamEfficiencyOfficersData: {},
        teamEfficiencyOfficersLoading: true,
        teamEfficiencyOfficersLoaded: false        
      };
    }

    case FETCH_DASHBOARD_TEAMEFFICIENCYOFFICERS_DATA: {
      return {
        ...state,
        teamEfficiencyOfficersData: action.payload === undefined ? {} : action.payload,
        teamEfficiencyOfficersLoading: false,
        teamEfficiencyOfficersLoaded: true
      };
    }

    case FETCH_DASHBOARD_TEAMEFFICIENCYOFFICERS_FAILED: {
      return {
        ...state,
        teamEfficiencyOfficersData: {},
        teamEfficiencyOfficersLoading: false,
        teamEfficiencyOfficersLoaded: false
      };
    }

    case FETCH_DASHBOARD_DISTRIBUTION_START: {
      return {
        ...state,
        distributionData: {'unitTime': [], 'unitCount': [], 'officerTime': [], 'officerCount': [] },
        distributionLoading: true,
        distributionLoaded: false        
      };
    }

    case FETCH_DASHBOARD_DISTRIBUTION_DATA: {
      return {
        ...state,
        distributionData: action.payload === undefined ? {'unitTime': [], 'unitCount': [], 'officerTime': [], 'officerCount': [] } : action.payload,
        distributionLoading: false,
        distributionLoaded: true
      };
    }

    case FETCH_DASHBOARD_DISTRIBUTION_FAILED: {
      return {
        ...state,
        distributionData: {'unitTime': [], 'unitCount': [], 'officerTime': [], 'officerCount': [] },
        distributionLoading: false,
        distributionLoaded: false
      };
    }

    case FETCH_DASHBOARD_DISTRIBUTION_ANDON_START: {
      return {
        ...state,
        distributionAndonData: {'unitTime': [], 'unitCount': [], 'officerTime': [], 'officerCount': [] },
        distributionAndonLoading: true,
        distributionAndonLoaded: false        
      };
    }

    case FETCH_DASHBOARD_DISTRIBUTION_ANDON_DATA: {
      return {
        ...state,
        distributionAndonData: action.payload === undefined ? {'unitTime': [], 'unitCount': [], 'officerTime': [], 'officerCount': [] } : action.payload,
        distributionAndonLoading: false,
        distributionAndonLoaded: true
      };
    }

    case FETCH_DASHBOARD_DISTRIBUTION_ANDON_FAILED: {
      return {
        ...state,
        distributionAndonData: {'unitTime': [], 'unitCount': [], 'officerTime': [], 'officerCount': [] },
        distributionAndonLoading: false,
        distributionAndonLoaded: false
      };
    }

    case FETCH_DASHBOARD_DISTRIBUTION_HELP_START: {
      return {
        ...state,
        distributionHelpData: {'unitTime': [], 'unitCount': [], 'officerTime': [], 'officerCount': [] },
        distributionHelpLoading: true,
        distributionHelpLoaded: false        
      };
    }

    case FETCH_DASHBOARD_DISTRIBUTION_HELP_DATA: {
      return {
        ...state,
        distributionHelpData: action.payload === undefined ? {'unitTime': [], 'unitCount': [], 'officerTime': [], 'officerCount': [] } : action.payload,
        distributionHelpLoading: false,
        distributionHelpLoaded: true
      };
    }

    case FETCH_DASHBOARD_DISTRIBUTION_HELP_FAILED: {
      return {
        ...state,
        distributionHelpData: {'unitTime': [], 'unitCount': [], 'officerTime': [], 'officerCount': [] },
        distributionHelpLoading: false,
        distributionHelpLoaded: false
      };
    }

    case FETCH_DASHBOARD_DISTRIBUTION_ISSUES_START: {
      return {
        ...state,
        issuesDistributionData: [],
        issuesDistributionDataLoading: true,
        issuesDistributionDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_DISTRIBUTION_ISSUES_SUCCESS: {
      return {
        ...state,
        issuesDistributionDataLoading: false
      };
    }

    case FETCH_DASHBOARD_DISTRIBUTION_ISSUES_DATA: {
      return {
        ...state,
        issuesDistributionData: action.payload === undefined ? [] : action.payload,
        issuesDistributionDataLoading: false,
        issuesDistributionDataLoaded: true
      };
    }

    case FETCH_DASHBOARD_DISTRIBUTION_COACHINGS_START: {
      return {
        ...state,
        coachingsDistributionData: [],
        coachingsDistributionDataLoading: true,
        coachingsDistributionDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_DISTRIBUTION_COACHINGS_SUCCESS: {
      return {
        ...state,
        coachingsDistributionDataLoading: false
      };
    }

    case FETCH_DASHBOARD_DISTRIBUTION_COACHINGS_DATA: {
      return {
        ...state,
        coachingsDistributionData: action.payload === undefined ? [] : action.payload,
        coachingsDistributionDataLoading: false,
        coachingsDistributionDataLoaded: true
      };
    }

    case FETCH_DASHBOARD_ISSUES_START: {
      return {
        ...state,
        issuesData: [],
        issuesRealtimeData: [],
        issuesDataLoading: true,
        issuesDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_ISSUES_SUCCESS: {
      return {
        ...state,
        issuesDataLoading: false
      };
    }

    case FETCH_DASHBOARD_ISSUES_DATA: {
      return {
        ...state,
        issuesData: action.payload === undefined ? [] : action.payload,
        issuesDataLoading: false,
        issuesDataLoaded: true
      };
    }

    case FETCH_DASHBOARD_ISSUES_REAL_TIME_DATA: {
      return {
        ...state,
        issuesRealtimeData: action.payload === undefined ? [] : action.payload,
        issuesDataLoading: false,
        issuesDataLoaded: true
      };
    }

    case FETCH_DASHBOARD_ISSUES_FAILED: {
      return {
        ...state,
        issuesData: [],
        issuesDataLoading: false,
        issuesDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_COACHINGS_START: {
      return {
        ...state,
        coachingsData: [],
        coachingsRealtimeData: [],
        coachingsDataLoading: true,
        coachingsDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_COACHINGS_SUCCESS: {
      return {
        ...state,
        coachingsDataLoading: false
      };
    }

    case FETCH_DASHBOARD_COACHINGS_DATA: {
      return {
        ...state,
        coachingsData: action.payload === undefined ? [] : action.payload,
        coachingsDataLoading: false,
        coachingsDataLoaded: true
      };
    }

    case FETCH_DASHBOARD_COACHINGS_REAL_TIME_DATA: {
      return {
        ...state,
        coachingsRealtimeData: action.payload === undefined ? [] : action.payload,
        coachingsDataLoading: false,
        coachingsDataLoaded: true
      };
    }

    case FETCH_DASHBOARD_COACHINGS_FAILED: {
      return {
        ...state,
        coachingsData: [],
        coachingsDataLoading: false,
        coachingsDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKS_START: {
      return {
        ...state,
        tasksRealtimeData: [],
        tasksDataLoading: true,
        tasksDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKS_SUCCESS: {
      return {
        ...state,
        tasksDataLoading: false
      };
    }

    case FETCH_DASHBOARD_TASKS_REAL_TIME_DATA: {
      return {
        ...state,
        tasksRealtimeData: action.payload === undefined ? [] : action.payload,
        tasksDataLoading: false,
        tasksDataLoaded: true
      };
    }


    case DASHBOARD_FILTERS_SELECTEDCOMPANY: {      
      return {
        ...state,
        dashboardFiltersSelectedCompany: action.payload
      };
    }

    case DASHBOARD_FILTERS_CLEAR_SELECTEDCOMPANY: {      
      return {
        ...state,
        companies: [],
        units: [],
        taskOfficers: []
      };
    }

    case DASHBOARD_FILTERS_SELECTEDUNIT: {
      return {
        ...state,
        dashboardFiltersSelectedUnit: action.payload
      };
    }

    case DASHBOARD_FILTERS_SELECTEDOFFICER: {
      return {
        ...state,
        dashboardFiltersSelectedOfficer: action.payload
      };
    }

    case DASHBOARD_FILTERS_SELECTEDTASK: {
      return {
        ...state,
        dashboardFiltersSelectedTask: action.payload
      };
    }    

    case DASHBOARD_FILTERS_SELECTEDDATEFROM: {
      return {
        ...state,
        dashboardFiltersSelectedDateFrom: action.payload
      };
    }

    case DASHBOARD_FILTERS_SELECTEDDATETO: {
      return {
        ...state,
        dashboardFiltersSelectedDateTo: action.payload
      };
    }

    case DASHBOARD_FILTERS_SHOWVALUE: {
      return {
        ...state,
        dashboardFiltersShowValue: action.payload
      };
    }

    case DASHBOARD_FILTERS_SELECTEDVIEWTYPE: {
      return {
        ...state,
        dashboardFiltersSelectedViewType: action.payload
      };
    }

    case DASHBOARD_FILTERS_SELECTEDISSUE: {
      return {
        ...state,
        dashboardFiltersSelectedIssue: action.payload
      };
    }

    case DASHBOARD_FILTERS_SELECTEDCOACHING: {
      return {
        ...state,
        dashboardFiltersSelectedCoaching: action.payload
      };
    }

    case DASHBOARD_FILTERS_SELECTEDLIMITTYPE: {
      return {
        ...state,
        dashboardFiltersSelectedLimitType: action.payload
      };
    }

    case DASHBOARD_FILTERS_SHOW_ANDON: {
      return {
        ...state,
        dashBoardFiltersShowAndon: action.payload
      };
    }

    case FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_START: {
      return {
        ...state,
        tableLogsData: [],
        tableLogsFilteredData: [],
        tableLogsDataLoading: true,
        tableLogsDataLoaded: false,
        updateSuccess: false
      };
    }

    case FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_DATA: {
      return {
        ...state,
        tableLogsData: action.payload === undefined ? [] : action.payload,
        tableLogsFilteredData: action.payload === undefined ? [] : action.payload,
        tableLogsDataLoading: false,
        tableLogsDataLoaded: true
      };
    }

    case FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_FAILED: {
      return {
        ...state,
        tableLogsData: [],
        tableLogsFilteredData: [],
        tableLogsDataLoading: false,
        tableLogsDataLoaded: false
      };
    }

    case FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_FILTEREDDATA: {
      return {
        ...state,
        tableLogsFilteredData: action.payload === undefined ? [] : action.payload,
      };
    }

    default:
      return state;
  }
}
