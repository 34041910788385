import React from 'react';
import ReactDOM from 'react-dom';
import { loadCldr, setCulture, setCurrencyCode, L10n } from '@syncfusion/ej2-base';

loadCldr(
  require('lngProvider/cldr-data/supplemental/numberingSystems.json'),
  require('lngProvider/cldr-data/main/fr-BE/ca-gregorian.json'),
  require('lngProvider/cldr-data/main/fr-BE/numbers.json'),
  require('lngProvider/cldr-data/main/fr-BE/timeZoneNames.json'),
  require('lngProvider/cldr-data/supplemental/weekData.json'));

setCulture('en');
setCurrencyCode('EUR');

L10n.load({
  'fr-BE': {
    "chart": {
      "Zoom": "Zoom",
      "ZoomIn": "Agrandir",
      "ZoomOut": "Dézoomer",
      "Reset": "Réinitialiser",
      "Pan": "Pan",
      "ResetZoom": "Réinitialiser le zoom"
    },
    "datetimepicker": {
      "placeholder": 'Veuillez sélectionner une date et une heure',
      "today": 'Aujourd\'hui'
    },
    "datepicker": {
      "placeholder": 'Veuillez sélectionner une date',
      "today": 'Aujourd\'hui'
    },    
    "schedule": {
      "day": "journée",
      "week": "La semaine",
      "workWeek": "Semaine de travail",
      "month": "Mois",
      "agenda": "Ordre du jour",
      "weekAgenda": "Agenda de la semaine",
      "workWeekAgenda": "Agenda de la semaine de travail",
      "monthAgenda": "Agenda du mois",
      "today": "Aujourd'hui",
      "noEvents": "Pas d'événements",
      "emptyContainer": "Aucun événement n'est prévu ce jour-là.",
      "allDay": "Toute la journée",
      "start": "Début",
      "end": "Fin",
      "more": "plus",
      "close": "Fermer",
      "cancel": "Annuler",
      "noTitle": "(Pas de titre)",
      "delete": "Supprimer",
      "deleteEvent": "Cet evènement",
      "deleteMultipleEvent": "Supprimer plusieurs événements",
      "selectedItems": "Articles sélectionnés",
      "deleteSeries": "Série entière",
      "edit": "Éditer",
      "editSeries": "Série entière",
      "editEvent": "Cet evènement",
      "createEvent": "Créer",
      "subject": "Matière",
      "addTitle": "Ajouter un titre",
      "moreDetails": "Plus de détails",
      "save": "sauvegarder",
      "editContent": "Comment aimeriez-vous changer le rendez-vous de la série?",
      "deleteContent": "Voulez-vous vraiment supprimer cet événement?",
      "deleteMultipleContent": "Voulez-vous vraiment supprimer les événements sélectionnés?",
      "newEvent": "Nouvel évènement",
      "title": "Titre",
      "location": "Emplacement",
      "description": "La description",
      "timezone": "Fuseau horaire",
      "startTimezone": "Démarrer le fuseau horaire",
      "endTimezone": "Fin du fuseau horaire",
      "repeat": "Répéter",
      "saveButton": "sauvegarder",
      "cancelButton": "Annuler",
      "deleteButton": "Supprimer",
      "recurrence": "Récurrence",
      "wrongPattern": "Le modèle de récurrence n'est pas valide.",
      "seriesChangeAlert": "Voulez-vous annuler les modifications apportées à des instances spécifiques de cette série et les associer à nouveau à l'ensemble de la série?",
      "createError": "La durée de l'événement doit être plus courte que sa fréquence. Raccourcissez la durée ou modifiez le modèle de récurrence dans l'éditeur d'événements de récurrence.",
      "sameDayAlert": "Deux occurrences du même événement ne peuvent pas se produire le même jour.",
      "editRecurrence": "Modifier la récurrence",
      "repeats": "Répète",
      "alert": "Alerte",
      "startEndError": "La date de fin sélectionnée se produit avant la date de début.",
      "invalidDateError": "La valeur de date entrée n'est pas valide.",
      "blockAlert": "Les événements ne peuvent pas être planifiés dans l'intervalle de temps bloqué.",
      "ok": "D'accord",
      "yes": "Oui",
      "no": "Non",
      "occurrence": "Occurrence",
      "series": "Séries",
      "previous": "précédent",
      "next": "Prochain",
      "timelineDay": "Jour de la chronologie",
      "timelineWeek": "Semaine chronologique",
      "timelineWorkWeek": "Semaine de travail",
      "timelineMonth": "Mois de la chronologie",
      "timelineYear": "Année de la chronologie",
      "editFollowingEvent": "Événements suivants",
      "deleteTitle": "Supprimer l'événement",
      "editTitle": "Modifier l'événement",
      "beginFrom": "Commencer à partir de",
      "endAt": "Fin à"
    },
    "pivotview": {
      "grandTotal": "somme finale",
      "total": "Total",
      "value": "Valeur",
      "noValue": "Aucune valeur",
      "row": "Rangée",
      "column": "Colonne",
      "collapse": "Effondrer",
      "expand": "Développer",
      "rowAxisPrompt": "Déposer la ligne ici",
      "columnAxisPrompt": "Déposer la colonne ici",
      "valueAxisPrompt": "Déposer la valeur ici",
      "filterAxisPrompt": "Déposer le filtre ici",
      "filter": "Filtre",
      "filtered": "Filtré",
      "sort": "Trier",
      "filters": "Filtres",
      "rows": "Lignes",
      "columns": "Colonnes",
      "values": "Valeurs",
      "close": "Fermer",
      "cancel": "Annuler",
      "delete": "Supprimer",
      "CalculatedField": "Champ calculé",
      "createCalculatedField": "Créer un champ calculé",
      "fieldName": "Entrez le nom du champ",
      "error": "Erreur",
      "invalidFormula": "Formule non valide.",
      "dropText": "Exemple: ('Sum (Order_Count)' + 'Sum (In_Stock)') * 250",
      "dropTextMobile": "Ajoutez des champs et modifiez la formule ici.",
      "dropAction": "Le champ calculé ne peut être placé dans aucune autre région à l'exception de l'axe des valeurs.",
      "alert": "Alerte",
      "warning": "avertissement",
      "ok": "D'accord",
      "search": "Chercher",
      "drag": "Traîne",
      "remove": "Retirer",
      "allFields": "Tous les champs",
      "formula": "Formule",
      "addToRow": "Ajouter à la ligne",
      "addToColumn": "Ajouter à la colonne",
      "addToValue": "Ajouter à la valeur",
      "addToFilter": "Ajouter au filtre",
      "emptyData": "Aucun enregistrement à afficher",
      "fieldExist": "Un champ existe déjà sous ce nom. Veuillez saisir un nom différent.",
      "confirmText": "Un champ de calcul existe déjà sous ce nom. Voulez-vous le remplacer?",
      "noMatches": "Pas de correspondance",
      "format": "Résume les valeurs par",
      "edit": "Éditer",
      "clear": "Clair",
      "formulaField": "Glissez et déposez les champs dans la formule",
      "dragField": "Faites glisser le champ vers la formule",
      "clearFilter": "Clair",
      "by": "par",
      "all": "Tout",
      "multipleItems": "Articles multiples",
      "member": "Membre",
      "label": "Étiquette",
      "date": "Date",
      "enterValue": "Entrez une valeur",
      "chooseDate": "Entrez la date",
      "Before": "Avant",
      "BeforeOrEqualTo": "Avant ou égal à",
      "After": "Après",
      "AfterOrEqualTo": "Après ou égal à",
      "labelTextContent": "Afficher les éléments pour lesquels l'étiquette",
      "dateTextContent": "Afficher les éléments pour lesquels la date",
      "valueTextContent": "Afficher les articles pour lesquels",
      "Equals": "Équivaut à",
      "DoesNotEquals": "N'est pas égal",
      "BeginWith": "Commence par",
      "DoesNotBeginWith": "Ne commence pas par",
      "EndsWith": "Se termine par",
      "DoesNotEndsWith": "Ne se termine pas par",
      "Contains": "Contient",
      "DoesNotContains": "Ne contient pas",
      "GreaterThan": "Plus grand que",
      "GreaterThanOrEqualTo": "Plus grand ou égal à",
      "LessThan": "Moins que",
      "LessThanOrEqualTo": "Inférieur ou égal à",
      "Between": "Entre",
      "NotBetween": "Pas entre",
      "And": "et",
      "Sum": "Somme",
      "Count": "Compter",
      "DistinctCount": "Nombre distinct",
      "Product": "Produit",
      "Avg": "Moy.",
      "Min": "Min",
      "SampleVar": "Échantillon Var",
      "PopulationVar": "Population Var",
      "RunningTotals": "Totaux en cours d'exécution",
      "Max": "Max",
      "Index": "Indice",
      "SampleStDev": "Exemple StDev",
      "PopulationStDev": "La population de StDev",
      "PercentageOfRowTotal": "% du total des lignes",
      "PercentageOfParentTotal": "% du total des parents",
      "PercentageOfParentColumnTotal": "% du total de la colonne parent",
      "PercentageOfParentRowTotal": "% du total de la ligne parent",
      "DifferenceFrom": "Différence de",
      "PercentageOfDifferenceFrom": "% de différence par rapport à",
      "PercentageOfGrandTotal": "% du grand total",
      "PercentageOfColumnTotal": "% du total de la colonne",
      "NotEquals": "Pas égal",
      "AllValues": "Toutes les valeurs",
      "conditionalFormating": "Mise en forme conditionnelle",
      "apply": "APPLIQUER",
      "condition": "Ajouter une condition",
      "formatLabel": "Format",
      "valueFieldSettings": "Paramètres de champ de valeur",
      "baseField": "Champ de base:",
      "baseItem": "Élément de base:",
      "summarizeValuesBy": "Résumez les valeurs en:",
      "sourceName": "Nom de domaine :",
      "sourceCaption": "Légende du champ:",
      "example": "par exemple:",
      "editorDataLimitMsg": " Plus d'articles. Recherchez pour affiner davantage.",
      "details": "Détails",
      "manageRecords": "Gérer les enregistrements",
      "Years": "Ans",
      "Quarters": "Quartiers",
      "Months": "Mois",
      "Days": "Journées",
      "Hours": "Heures",
      "Minutes": "Minutes",
      "Seconds": "Secondes",
      "save": "Enregistrer un rapport",
      "new": "Créer un nouveau rapport",
      "load": "Charge",
      "saveAs": "Enregistrer en tant que rapport actuel",
      "rename": "Renommer un rapport actuel",
      "deleteReport": "Supprimer un rapport actuel",
      "export": "Exportation",
      "subTotals": "Sous-totaux",
      "grandTotals": "Totaux généraux",
      "reportName": "Nom du rapport:",
      "pdf": "PDF",
      "excel": "Exceller",
      "csv": "CSV",
      "png": "PNG",
      "jpeg": "JPEG",
      "svg": "SVG",
      "mdxQuery": "Requête MDX",
      "showSubTotals": "Afficher les sous-totaux",
      "doNotShowSubTotals": "Ne pas afficher les sous-totaux",
      "showSubTotalsRowsOnly": "Afficher uniquement les lignes de sous-totaux",
      "showSubTotalsColumnsOnly": "Afficher uniquement les colonnes de sous-totaux",
      "showGrandTotals": "Afficher les totaux généraux",
      "doNotShowGrandTotals": "Ne pas afficher les totaux généraux",
      "showGrandTotalsRowsOnly": "Afficher uniquement les lignes des totaux généraux",
      "showGrandTotalsColumnsOnly": "Afficher uniquement les colonnes des totaux généraux",
      "fieldList": "Afficher la liste des champs",
      "grid": "Afficher le tableau",
      "toolbarFormatting": "Mise en forme conditionnelle",
      "chart": "Graphique",
      "reportMsg": "Veuillez entrer le nom du rapport vaild !!!",
      "reportList": "Liste des rapports",
      "removeConfirm": "Voulez-vous vraiment supprimer ce rapport?",
      "emptyReport": "Aucun rapport trouvé !!",
      "bar": "Bar",
      "line": "Ligne",
      "area": "Zone",
      "scatter": "Dispersion",
      "polar": "Polaire",
      "of": "de",
      "emptyFormat": "Aucun format trouvé !!!",
      "emptyInput": "Entrez une valeur",
      "newReportConfirm": "Vous souhaitez enregistrer les modifications à signaler?",
      "emptyReportName": "Entrez un nom de rapport",
      "qtr": "Qtr",
      "null": "nul",
      "undefined": "indéfini",
      "groupOutOfRange": "Hors de portée",
      "fieldDropErrorAction": "Le champ que vous déplacez ne peut pas être placé dans cette zone du rapport",
      "MoreOption": "Plus...",
      "aggregate": "Agrégat",
      "drillThrough": "Percer à travers",
      "ascending": "Ascendant",
      "descending": "Descendant",
      "number": "Nombre",
      "currency": "Devise",
      "percentage": "Pourcentage",
      "formatType": "Type de format",
      "customText": "Symbole de la monnaie",
      "symbolPosition": "Position du symbole",
      "left": "La gauche",
      "right": "Droite",
      "grouping": "Regroupement",
      "true": "Vrai",
      "false": "Faux",
      "decimalPlaces": "Décimales",
      "numberFormat": "Formatage des nombres",
      "memberType": "Type de champ",
      "formatString": "Format de chaîne",
      "expressionField": "Expression",
      "customFormat": "Entrez une chaîne de format personnalisé",
      "selectedHierarchy": "Hiérarchie parente",
      "olapDropText": "Exemple: [Mesures]. [Quantité de commande] + ([Mesures]. [Quantité de commande] * 0,10)",
      "Percent": "Pour cent",
      "Custom": "Douane",
      "Measure": "Mesure",
      "Dimension": "Dimension",
      "Standard": "la norme",
      "blank": "(Vide)",
      "fieldTooltip": "Faites glisser et déposez les champs pour créer une expression. Et, si vous souhaitez modifier les champs calculés existants! Ensuite, vous pouvez y parvenir en sélectionnant simplement le champ sous «Membres calculés».",
      "QuarterYear": "Trimestre année",
      "fieldTitle": "Nom de domaine",
      "drillError": "Impossible d'afficher les éléments bruts des champs calculés.",
      "caption": "Légende de champ",
      "copy": "Copie",
      "defaultReport": "Rapport par défaut",
      "customFormatString": "Format personnalisé",
      "invalidFormat": "Format invalide.",
      "group": "Groupe",
      "unGroup": "Dissocier",
      "invalidSelection": "Impossible de regrouper cette sélection.",
      "groupName": "Entrez la légende à afficher dans l'en-tête",
      "captionName": "Entrez la légende pour le champ de groupe",
      "selectedItems": "Éléments sélectionnés",
      "groupFieldCaption": "Légende de champ",
      "groupTitle": "Nom de groupe",
      "startAt": "À partir de",
      "endAt": "Se terminant à",
      "groupBy": "Intervalle de",
      "selectGroup": "Sélectionnez des groupes",
      "numberFormatString": "Exemple: C, P, 0000%, ### 0. ## 0 #, etc.",
      "stackingcolumn": "Colonne empilée",
      "stackingbar": "Bar empilé",
      "stackingarea": "Zone empilée",
      "stepline": "Step Line",
      "steparea": "Zone de l'étape",
      "splinearea": "Zone de spline",
      "spline": "Spline",
      "stackingcolumn100": "Colonne empilée à 100%",
      "stackingbar100": "Barre 100% empilée",
      "stackingarea100": "Zone 100% empilée",
      "bubble": "bulle",
      "distribution": "Distribution",
      "radar": "Radar",
      "chartTypeSettings": "Paramètres de type de graphique",
      "multipleAxes": "Plusieurs axes",
      "sortAscending": "Trier l'ordre croissant",
      "sortDescending": "Trier l'ordre décroissant",
      "sortNone": "Trier l'ordre des données",
      "clearCalculatedField": "Effacer les informations des champs modifiés",
      "editCalculatedField": "Modifier le champ calculé",
      "ChartType": "Type de graphique",
      "yes": "Oui",
      "no": "Non",
      "numberFormatMenu": "Formatage des nombres ...",
      "conditionalFormatingMenu": "Mise en forme conditionnelle...",
      "removeCalculatedField": "Voulez-vous vraiment supprimer ce champ calculé?",
      "replaceConfirmBefore": "Un rapport nommé",
      "replaceConfirmAfter": " existe déjà. Voulez-vous le remplacer?"
    },
    "pivotfieldlist": {
      "staticFieldList": "Liste des champs de pivot",
      "fieldList": "Liste de champ",
      "dropFilterPrompt": "Déposer le filtre ici",
      "dropColPrompt": "Déposer la colonne ici",
      "dropRowPrompt": "Déposer la ligne ici",
      "dropValPrompt": "Déposer la valeur ici",
      "addPrompt": "Ajouter un champ ici",
      "adaptiveFieldHeader": "Choisissez un champ",
      "centerHeader": "Faites glisser les champs entre les axes ci-dessous:",
      "add": "ajouter",
      "drag": "Traîne",
      "filter": "Filtre",
      "filtered": "Filtré",
      "sort": "Trier",
      "remove": "Retirer",
      "filters": "Filtres",
      "rows": "Lignes",
      "columns": "Colonnes",
      "values": "Valeurs",
      "CalculatedField": "Champ calculé",
      "createCalculatedField": "Créer un champ calculé",
      "fieldName": "Entrez le nom du champ",
      "error": "Erreur",
      "invalidFormula": "Formule non valide.",
      "dropText": "Exemple: ('Sum (Order_Count)' + 'Sum (In_Stock)' ') * 250",
      "dropTextMobile": "Ajoutez des champs et modifiez la formule ici.",
      "dropAction": "Le champ calculé ne peut être placé dans aucune autre région à l'exception de l'axe des valeurs.",
      "search": "Chercher",
      "close": "Fermer",
      "cancel": "Annuler",
      "delete": "Supprimer",
      "alert": "Alerte",
      "warning": "avertissement",
      "ok": "D'accord",
      "allFields": "Tous les champs",
      "formula": "Formule",
      "fieldExist": "Un champ existe déjà sous ce nom. Veuillez saisir un nom différent.",
      "confirmText": "Un champ de calcul existe déjà sous ce nom. Voulez-vous le remplacer?",
      "noMatches": "Pas de correspondance",
      "format": "Résume les valeurs par",
      "edit": "Éditer",
      "clear": "Clair",
      "formulaField": "Glissez et déposez les champs dans la formule",
      "dragField": "Faites glisser le champ vers la formule",
      "clearFilter": "Clair",
      "by": "par",
      "enterValue": "Entrez une valeur",
      "chooseDate": "Entrez la date",
      "all": "Tout",
      "multipleItems": "Articles multiples",
      "Equals": "Équivaut à",
      "DoesNotEquals": "N'est pas égal",
      "BeginWith": "Commence par",
      "DoesNotBeginWith": "Ne commence pas par",
      "EndsWith": "Se termine par",
      "DoesNotEndsWith": "Ne se termine pas par",
      "Contains": "Contient",
      "DoesNotContains": "Ne contient pas",
      "GreaterThan": "Plus grand que",
      "GreaterThanOrEqualTo": "Plus grand ou égal à",
      "LessThan": "Moins que",
      "LessThanOrEqualTo": "Inférieur ou égal à",
      "Between": "Entre",
      "NotBetween": "Pas entre",
      "Before": "Avant",
      "BeforeOrEqualTo": "Avant ou égal à",
      "After": "Après",
      "AfterOrEqualTo": "Après ou égal à",
      "member": "Membre",
      "label": "Étiquette",
      "date": "Date",
      "value": "Valeur",
      "labelTextContent": "Afficher les éléments pour lesquels l'étiquette",
      "dateTextContent": "Afficher les éléments pour lesquels la date",
      "valueTextContent": "Afficher les articles pour lesquels",
      "And": "et",
      "Sum": "Somme",
      "Count": "Compter",
      "DistinctCount": "Nombre distinct",
      "Product": "Produit",
      "Avg": "Moy.",
      "Min": "Min",
      "Max": "Max",
      "Index": "Indice",
      "SampleStDev": "Exemple StDev",
      "PopulationStDev": "La population de StDev",
      "SampleVar": "Échantillon Var",
      "PopulationVar": "Population Var",
      "RunningTotals": "Totaux en cours d'exécution",
      "DifferenceFrom": "Différence de",
      "PercentageOfDifferenceFrom": "% de différence par rapport à",
      "PercentageOfGrandTotal": "% du grand total",
      "PercentageOfColumnTotal": "% du total de la colonne",
      "PercentageOfRowTotal": "% du total des lignes",
      "PercentageOfParentTotal": "% du total des parents",
      "PercentageOfParentColumnTotal": "% du total de la colonne parent",
      "PercentageOfParentRowTotal": "% du total de la ligne parent",
      "Years": "Ans",
      "Quarters": "Quartiers",
      "Months": "Mois",
      "Days": "Journées",
      "Hours": "Heures",
      "Minutes": "Minutes",
      "Seconds": "Secondes",
      "apply": "APPLIQUER",
      "valueFieldSettings": "Paramètres de champ de valeur",
      "sourceName": "Nom de domaine :",
      "sourceCaption": "Légende du champ:",
      "summarizeValuesBy": "Résumez les valeurs en:",
      "baseField": "Champ de base:",
      "baseItem": "Élément de base:",
      "example": "par exemple:",
      "editorDataLimitMsg": " Plus d'articles. Recherchez pour affiner davantage.",
      "deferLayoutUpdate": "Différer la mise à jour de la mise en page",
      "null": "nul",
      "undefined": "indéfini",
      "groupOutOfRange": "Hors de portée",
      "fieldDropErrorAction": "Le champ que vous déplacez ne peut pas être placé dans cette zone du rapport",
      "MoreOption": "Plus...",
      "memberType": "Type de champ",
      "selectedHierarchy": "Hiérarchie parente",
      "formatString": "Format de chaîne",
      "expressionField": "Expression",
      "olapDropText": "Exemple: [Mesures]. [Quantité de commande] + ([Mesures]. [Quantité de commande] * 0,10)",
      "customFormat": "Entrez une chaîne de format personnalisé",
      "Measure": "Mesure",
      "Dimension": "Dimension",
      "Standard": "la norme",
      "Currency": "Devise",
      "Percent": "Pour cent",
      "Custom": "Douane",
      "blank": "(Vide)",
      "fieldTooltip": "Faites glisser et déposez les champs pour créer une expression. Et, si vous souhaitez modifier les champs calculés existants! Vous pouvez y parvenir en sélectionnant simplement le champ sous «Membres calculés».",
      "fieldTitle": "Nom de domaine",
      "QuarterYear": "Trimestre année",
      "caption": "Légende de champ",
      "copy": "Copie",
      "group": "Groupe",
      "numberFormatString": "Exemple: C, P, 0000%, ### 0. ## 0 #, etc.",
      "sortAscending": "Trier l'ordre croissant",
      "sortDescending": "Trier l'ordre décroissant",
      "sortNone": "Trier l'ordre des données",
      "clearCalculatedField": "Effacer les informations des champs modifiés",
      "editCalculatedField": "Modifier le champ calculé",
      "selectGroup": "Sélectionnez des groupes",
      "of": "de",
      "removeCalculatedField": "Voulez-vous vraiment supprimer ce champ calculé?"
    }
  }
});

const rootEl = document.getElementById('app-site');

// Create a reusable render method that we can call more than once
let render = () => {
  // Dynamically import our main App component, and render it
  const MainApp = require('./MainApp').default;
  ReactDOM.render(
    <MainApp />,
    rootEl
  );
};

if (module.hot) {
  module.hot.accept('./MainApp', () => {
    const MainApp = require('./MainApp').default;
    render(
      <MainApp />,
      rootEl
    );
  });
}


render();
