import React, { useState, useEffect } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from 'util/Api';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { COLLAPSED_DRAWER, FIXED_DRAWER } from "constants/ActionTypes";
// import MailNotification from "../../../../components/MailNotification";
// import AppNotification from "../../../../components/AppNotification";
// import CardHeader from "components/dashboard/Common/CardHeader/index";
// import { switchLanguage, toggleCollapsedNav } from "actions/Setting";
import { toggleCollapsedNav } from "actions/Setting";
import { setDefaultCompany } from "actions/Common";
import { setError, cleanError } from "actions/Errors";
// import LanguageSwitcher from "components/LanguageSwitcher/index";
import CompanySwitcher from "../../CompanySwitcher/index";

const Index = (props) => {

  const dispatch = useDispatch();

  const { token, isSessionTimedOut } = useSelector(({ auth }) => auth);
  const [loaded, setLoaded] = useState(false);
  const [companies, setCompanies] = useState([]);

  // const { drawerType, locale } = useSelector(({ settings }) => settings);
  const { drawerType } = useSelector(({ settings }) => settings);
  // const [langSwitcher, setLangSwitcher] = useState(false);
  // const [mailNotification, setMailNotification] = useState(false);
  // const [appNotification, setAppNotification] = useState(false);
  // const [apps, setApps] = useState(false);

  // const onAppNotificationSelect = () => {
  //   setAppNotification(!appNotification)
  // };

  // const onMailNotificationSelect = () => {
  //   setMailNotification(!mailNotification)
  // };
  // const onLangSwitcherSelect = () => {
  //   setLangSwitcher(!langSwitcher);
  // };

  // const onAppsSelect = () => {
  //   setApps(!apps)
  // };

  const handleRequestClose = () => {
    // setLangSwitcher(false);
    setCompanySwitcher(false);
    // setMailNotification(false);
    // setApps(false);
  };

  const onToggleCollapsedNav = (e) => {
    const val = !props.navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

  // const onSwitchLanguage = (lang) => {
  //   dispatch(switchLanguage(lang))
  // };

  const [companySwitcher, setCompanySwitcher] = useState(false);
  const { defaultCompany } = useSelector(({ commonData }) => commonData);
  const authUser = useSelector(({auth}) => auth.authUser);
  const onSwitchCompany = (company) => {
    dispatch(setDefaultCompany(company));
  };

  const onCompanySwitcherSelect = () => {
    setCompanySwitcher(!companySwitcher);
  };

  const drawerStyle = drawerType.includes(FIXED_DRAWER) ? "d-block d-xl-none" : drawerType.includes(COLLAPSED_DRAWER) ? "d-block" : "d-none";

  useEffect(() => {
    if (!loaded) {
      if (token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
      }
      axios.get('/companies')
        .then(({ data }) => {
          if (data.result) {              
            dispatch(cleanError());
            if (authUser.isManager || authUser.isOfficer){
              let companies = [...data.result.companies];
              if (companies.length > 0) {
                dispatch(setDefaultCompany(companies[0]));
              }              
              setCompanies(companies);
            } else {
              let defaultCompany = [{ companyId: -1, name: "Toutes" }];
              let companies = [...defaultCompany, ...data.result.companies];
              dispatch(setDefaultCompany(defaultCompany[0]));
              setCompanies(companies);
            }  
            
          } else {
            dispatch(cleanError());
            console.log("payload: data.error", data.error);
            return []
          }
        }).catch(function (error) {
          dispatch(setError(error));
          console.log("error.response.status:", error.response.status);
          console.log("Error****:", error.message);
          return [];
        });
      setLoaded(true);
    }
  }, [token, authUser, loaded, dispatch]);
  
  return (
    isSessionTimedOut ? <Redirect to="/SignIn" /> :
    !loaded ? <div /> :
    <AppBar className="app-main-header">
      <Toolbar className="app-toolbar" disableGutters={false}>

        <IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu"
          onClick={onToggleCollapsedNav}>
          <span className="menu-icon" />
        </IconButton>

        <Link className="app-logo mr-2 d-none d-sm-block" to="/">
          <img src={require("assets/images/DIGITALIS-EXTIME-LOGOTYPE-2021-11.png")} alt="Extime" title="Extime" />
          <img src={require("assets/images/DIGITALIS-EXTIME-LOGOTYPE-2021-03.png")} alt="Extime" title="Extime" />
        </Link>

        <ul className="header-notifications list-inline ml-auto">
          <li className="list-inline-item">
            <Dropdown
              className="quick-menu"
              isOpen={companySwitcher}
              toggle={onCompanySwitcherSelect}>

              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown">
                <span className="app-notification-menu">
                  <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg" />
                  <span>{defaultCompany.name}</span>
                </span>
              </DropdownToggle>

              <DropdownMenu right className="w-50">
                <CompanySwitcher switchCompany={onSwitchCompany}
                  handleRequestClose={handleRequestClose} data={companies} />
              </DropdownMenu>
            </Dropdown>


          </li>
          {/* <li className="list-inline-item">
            <Dropdown
              className="quick-menu app-notification"
              isOpen={apps}
              toggle={onAppsSelect}>

              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown">
                <span className="app-notification-menu">
                  <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg" />
                  <span>Apps</span>
                </span>
              </DropdownToggle>

              <DropdownMenu>
                {Apps()}
              </DropdownMenu>
            </Dropdown>
          </li> */}
          {/* <li className="list-inline-item">
            <Dropdown
              className="quick-menu"
              isOpen={langSwitcher}
              toggle={onLangSwitcherSelect}>

              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown">
                <IconButton className="icon-btn">
                  <i className={`flag flag-24 flag-${locale.icon}`} />
                </IconButton>
              </DropdownToggle>

              <DropdownMenu right className="w-50">
                <LanguageSwitcher switchLanguage={onSwitchLanguage}
                  handleRequestClose={handleRequestClose} />
              </DropdownMenu>
            </Dropdown>


          </li> */}
          {/* <li className="list-inline-item app-tour">
            <Dropdown
              className="quick-menu"
              isOpen={appNotification}
              toggle={onAppNotificationSelect}>

              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown">
                <IconButton className="icon-btn">
                  <i className="zmdi zmdi-notifications-none icon-alert animated infinite wobble" />
                </IconButton>
              </DropdownToggle>

              <DropdownMenu right>
                <CardHeader styleName="align-items-center"
                  heading={<IntlMessages id="appNotification.title" />} />
                <AppNotification />
              </DropdownMenu>
            </Dropdown>
          </li> */}
          {/* <li className="list-inline-item mail-tour">
            <Dropdown
              className="quick-menu"
              isOpen={mailNotification}
              toggle={onMailNotificationSelect}
            >
              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown">

                <IconButton className="icon-btn">
                  <i className="zmdi zmdi-comment-alt-text zmdi-hc-fw" />
                </IconButton>
              </DropdownToggle>


              <DropdownMenu right>
                <CardHeader styleName="align-items-center"
                  heading={<IntlMessages id="mailNotification.title" />} />
                <MailNotification />
              </DropdownMenu>
            </Dropdown>
          </li> */}

        </ul>

        <div className="ellipse-shape" />
      </Toolbar>
    </AppBar>
  );
};


export default withRouter(Index);
