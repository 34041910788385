import {COMPANIES_DATA} from "../constants/ActionTypes";

const INIT_STATE = {
  companies: [],
  companiesLoaded: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case COMPANIES_DATA: {
      return {
        ...state,
        companies: action.payload === undefined ? [] : action.payload,
        companiesLoaded: true
      };
    }

    default:
      return state;
  }
}
