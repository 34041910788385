import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    TASKS_DATA,
    FILTERED_TASKS_DATA,
    UPDATE_TASK_START,
    UPDATE_TASK_SUCCESS,
    DELETE_TASK_START,
    DELETE_TASK_SUCCESS,
    OFFICER_AFFECTABLE_TASKS_DATA,
    TASK_RECORD,
    DASHBOARD_FILTERS_CLEAR_SELECTEDTASK,
    TASK_OFFICERS_START,
    TASK_OFFICERS_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    ERROR_OTHERS
} from "../constants/ActionTypes";
import axios from 'util/Api';


export const fetchTasks = (unitId) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.get('/tasks', {
            params: {
                unitId: unitId
            }
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: TASKS_DATA, payload: data.result.tasks, unitId: unitId });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error?.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const sortData = (data, order, orderBy) => {
    return (dispatch) => {
        order === "desc"
            ? data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
            : data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
        dispatch({ type: FILTERED_TASKS_DATA, payload: data });
    }
};

export const filterData = (data, filterText) => {
    return (dispatch) => {
        var filteredData = data.filter(function(text) {
                if (filterText.length > 0) {
                    return text.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
                } else {
                    return true;
                }
          });
        dispatch({ type: FILTERED_TASKS_DATA, payload: filteredData });
    }
};

export const updateOrCreateTask = (task) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_TASK_START, payload: task.taskId });
        axios.post('/tasks', {
            ...task
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: UPDATE_TASK_SUCCESS, payload: data.result.task.taskId });
                    dispatch({ type: TASK_RECORD, payload: data.result.task });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const deleteTask = (task) => {
    return (dispatch) => {
        dispatch({ type: DELETE_TASK_START });
        axios.delete('/tasks', {
            data: {...task}
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: DELETE_TASK_SUCCESS });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const clearDashboardFiltersSelectedTask = () => {
    return (dispatch) => {
        dispatch({ type: DASHBOARD_FILTERS_CLEAR_SELECTEDTASK });
    }
}

export const fetchOfficerAffectableTasks= (officerId) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.get('/tasks', {
            params: {
                officerId: officerId
            }
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: OFFICER_AFFECTABLE_TASKS_DATA, payload: data.result.units });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const fetchTaskOfficers= (taskId,unitId) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START, taskId: taskId });
        dispatch({ type: TASK_OFFICERS_START});
        axios.get('/taskOfficers', {
            params: {
                taskId: taskId,
                unitId: unitId
            }
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: TASK_OFFICERS_SUCCESS, payload: data.result.officers, taskId: taskId});
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};