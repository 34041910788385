export const commonTaskTypes = [
    {
        name:"Work",
        value:1,
        subject:"Work",
    },
    {
        name:"Problem",
        value:2,
        subject:"Aide",
    },
    {
        name:"Support",
        value:3,
        subject:"Coaching",
    },
    {
        name:"Pause",
        value:4,
        subject:"Pause",
    },
    {
        name:"Lock/LogOff",
        value:5,
        subject:"Lock/Logoff",
    }
];

/** Get common task object from subject
 * 
 * @param {string} subject 
 * @returns common task object (name, value, subject)
 */
export function getCommonTaskFromSubject(subject) {
    return commonTaskTypes.find(ctt => ctt.subject === subject) || commonTaskTypes[0];
};

/** Get common task subject from value
 * 
 * @param {int} value 
 * @param {boolean} isFirst is the first task of the timeline
 * @returns common task subject (string)
 */
export function getSubjectFromValue(value, isFirst) {
    if (value === 1) {
        return isFirst ? "Nouvelle tâche" : "Reprise";
    }
    return commonTaskTypes.find(ctt => ctt.value === value)?.subject || "Reprise";
};