import {
  FETCH_ALL_UNITS_START, 
  UNITS_DATA, 
  FILTERED_UNITS_DATA, 
  UNITS_TREE_DATA, 
  UNIT_RECORD, 
  UPDATE_UNIT_START, 
  UPDATE_UNIT_SUCCESS, 
  DELETE_UNIT_START, 
  DELETE_UNIT_SUCCESS, 
  SELECT_UNIT, 
  OFFICER_UNITS_DATA, 
  DASHBOARD_FILTERS_CLEAR_SELECTEDUNIT
} from "../constants/ActionTypes";

const INIT_STATE = {
  units: [],
  filteredunits: [],
  tree: [{"id": "cpn0", "gender": "male", "name": "Toutes", "parents": [], "siblings": [], "spouses": [], "children": []}],
  treeRootId: "cpn0",
  selectedUnit: null,
  updatedUnitId: 0,
  updatingUnit: false,
  unitsLoaded: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case FETCH_ALL_UNITS_START: {
      return {
        ...state,
        unitsLoaded: false
      };        
    }

    case UNITS_DATA: {
      return {
        ...state,
        units: action.payload === undefined ? [] : action.payload,
        filteredunits: action.payload === undefined ? [] : action.payload,
        unitsLoaded: true
      };
    }

    case FILTERED_UNITS_DATA: {
      return {
        ...state,
        filteredunits: action.payload === undefined ? [] : action.payload,
      };
    }     

    case UNITS_TREE_DATA: {
      return {
        ...state,
        tree: action.payload,
        treeRootId: action.payload[0].name === "Toutes" ? action.payload[1].id : action.payload[1].id
      };
    }

    case UPDATE_UNIT_START: {
      return {
        ...state,
        updateSuccess: false,
        updating: true,
        updatedUnitId: action.payload,
        updatingUnit: true
      };
    }
    
    case UPDATE_UNIT_SUCCESS: {
      return {
        ...state,
        updateSuccess: true,
        updating: false,
        updatedUnitId: action.payload,
        updatingUnit: false
      };
    }    

    case UNIT_RECORD: {
      return {
        ...state,
        selectedUnit: action.payload
      };
    }
    
    case DELETE_UNIT_START: {
      return {
        ...state,
        updatingUnit: true
      };
    }

    case DELETE_UNIT_SUCCESS: {
      return {
        ...state,
        updatingUnit: false
      };
    }    

    case SELECT_UNIT: {
      return {
        ...state,
        selectedUnit: action.payload
      };      
    }

    case OFFICER_UNITS_DATA: {
      return {
        ...state,
        ['units' + action.officerId]: action.payload === undefined ? [] : action.payload
      };
    }
    
    case DASHBOARD_FILTERS_CLEAR_SELECTEDUNIT: {
      return {
        ...state,
        units: []
      };
    }    

    default:
      return state;
  }
}
