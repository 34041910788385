import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    FETCH_ALL_UNITS_START,
    UNITS_DATA,
    UNITS_TREE_DATA,
    FILTERED_UNITS_DATA,
    UPDATE_UNIT_START,
    UPDATE_UNIT_SUCCESS,
    DELETE_UNIT_START,
    DELETE_UNIT_SUCCESS,
    UNIT_RECORD,
    SELECT_UNIT,
    ADD_UNIT_OFFICER_SUCCESS,
    DASHBOARD_FILTERS_CLEAR_SELECTEDUNIT,
    SIGNOUT_USER_SUCCESS,
    ERROR_OTHERS,
    OFFICER_UNITS_DATA
} from "../constants/ActionTypes";
import axios from 'util/Api';
import { toast } from 'react-toastify';

export const fetchUnits = (defaultCompanyId, unitId, loadCompany) => {
    return (dispatch) => {
        dispatch({ type: FETCH_ALL_UNITS_START });
        axios.get('/units', {
            params: {
                defaultCompanyId: defaultCompanyId,
                unitId: unitId,
                loadCompany: loadCompany
            }
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: UNITS_DATA, payload: data.result.units });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({ type: SIGNOUT_USER_SUCCESS });
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const sortData = (data, order, orderBy) => {
    return (dispatch) => {
        order === "desc"
            ? data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
            : data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
        dispatch({ type: UNITS_DATA, payload: data });
    }
};

export const filterData = (data, filterText) => {
    return (dispatch) => {
        var filteredData = data.filter(function (text) {
            if (filterText.length > 0) {
                return ((text.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0) || (text.parentName === null ? false : (text.parentName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0)));
            } else {
                return true;
            }
        });
        dispatch({ type: FILTERED_UNITS_DATA, payload: filteredData });
    }
};

export const fetchUnitsTree = (defaultCompanyId, unitId) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.get('/gettree', {
            params: {
                defaultCompanyId: defaultCompanyId,
                unitId: unitId === undefined ? 0 : unitId
            }
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: UNITS_TREE_DATA, payload: data.result.tree });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response?.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({ type: SIGNOUT_USER_SUCCESS });
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const updateOrCreateUnit = (unit, refreshListAndTree, loadCompany) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_UNIT_START, payload: unit.unitId });
        var retValue = null;

        if (unit.unitId === 0) {
            axios.post('/units', {
                ...unit
            })
                .then(({ data }) => {
                    if (data.result) {
                        if (data.result.error.errorId < 0) {
                            toast.error(data.result.error.errorMessage, {
                                position: "top-center",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                });                            
                        } else {
                            dispatch({ type: UPDATE_UNIT_SUCCESS, payload: data.result.unit.unitId });
                            dispatch({ type: UNIT_RECORD, payload: data.result.unit });
                            retValue = data.result.unit;
                        }
                    } else {
                        console.log("payload: data.error", data.error);
                        dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                    }
                })
                .then(() => {
                    if (refreshListAndTree && retValue !== null) {
                        dispatch(fetchUnits(retValue.companyId, retValue.unitId, loadCompany));
                        dispatch(fetchUnitsTree(retValue.companyId, retValue.unitId));
                    }
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("user");
                        dispatch({ type: SIGNOUT_USER_SUCCESS });
                    } else {
                        dispatch({ type: ERROR_OTHERS, payload: error });
                        toast.error(error, {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            }); 
                    }
                    dispatch({ type: FETCH_ERROR, payload: error.message });
                    console.log("Error****:", error.message);
                    toast.error(error.message, {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        }); 
                });
        } else {
            axios.put('/units', {
                ...unit
            })
                .then(({ data }) => {
                    if (data.result) {
                        if (data.result.error.errorId < 0) {
                            toast.error(data.result.error.errorMessage, {
                                position: "top-center",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                });
                        } else {
                            dispatch({ type: UPDATE_UNIT_SUCCESS, payload: data.result.unit.unitId });
                            dispatch({ type: UNIT_RECORD, payload: data.result.unit });
                            retValue = data.result.unit
                        }                        
                    } else {
                        console.log("payload: data.error", data.error);
                        dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                    }
                })
                .then(() => {
                    if (refreshListAndTree && retValue !== null) {
                        dispatch(fetchUnits(retValue.companyId, retValue.unitId, loadCompany));
                        dispatch(fetchUnitsTree(retValue.companyId, retValue.unitId));
                    }
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("user");
                        dispatch({ type: SIGNOUT_USER_SUCCESS });
                    } else {
                        dispatch({ type: ERROR_OTHERS, payload: error });
                        toast.error(error, {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            }); 
                    }
                    dispatch({ type: FETCH_ERROR, payload: error.message });
                    console.log("Error****:", error.message);
                    toast.error(error.message, {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        }); 
                });
        }

    }
};

export const selectUnit = (selectedUnit) => {
    return (dispatch) => {
        dispatch({ type: SELECT_UNIT, payload: selectedUnit });
    }
}

export const fetchOfficerUnits = (officerId) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.get('/units', {
            params: {
                officerId: officerId
            }
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: OFFICER_UNITS_DATA, payload: data.result.units, officerId: officerId });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({ type: SIGNOUT_USER_SUCCESS });
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const deleteOfficerUnit = (unit) => {
    return (dispatch) => {
        dispatch({ type: DELETE_UNIT_START });
        axios.delete('/officerUnits', {
            data: { ...unit }
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: DELETE_UNIT_SUCCESS });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({ type: SIGNOUT_USER_SUCCESS });
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const deleteUnit = (unit, defaultCompany) => {
    return (dispatch) => {
        dispatch({ type: DELETE_UNIT_START });
        axios.delete('/units', {
            data: { ...unit }
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: DELETE_UNIT_SUCCESS });
                    dispatch(fetchUnits(defaultCompany.defaultCompanyId, unit.unitId, false));
                    dispatch(fetchUnitsTree(defaultCompany.defaultCompanyId));
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({ type: SIGNOUT_USER_SUCCESS });
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const createOfficerUnit = (unit, errorAction) => {
    return (dispatch) => {
        axios.post('/officerUnits', {
            ...unit
        })
            .then(({ data }) => {
                if (data.result) {
                    if (data.result.error.errorId < 0) {
                        toast.error(data.result.error.errorMessage, {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            });
                    } else {
                        dispatch({ type: ADD_UNIT_OFFICER_SUCCESS, payload: unit.unitId });
                    }                                         
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({ type: SIGNOUT_USER_SUCCESS });
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                    toast.error(error, {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
                toast.error(error.message, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    });
            });
    }
};

export const clearDashboardFiltersSelectedUnit = () => {
    return (dispatch) => {
        dispatch({ type: DASHBOARD_FILTERS_CLEAR_SELECTEDUNIT });
    }
}