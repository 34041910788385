import {
    UPDATE_TASK_START, 
    UPDATE_TASK_SUCCESS, 
    TASKS_DATA, 
    FILTERED_TASKS_DATA, 
    TASK_RECORD, 
    DELETE_TASK_START, 
    DELETE_TASK_SUCCESS,
    DASHBOARD_FILTERS_CLEAR_SELECTEDTASK,
    OFFICER_AFFECTABLE_TASKS_DATA,
    TASK_OFFICERS_START,
    TASK_OFFICERS_SUCCESS
} from "../constants/ActionTypes";

const INIT_STATE = {
  filteredtasks: [],
  tasks:[],
  tasksLoaded: false,
  taskId: 0,
  updatedTaskId: 0,
  updatingTask: false,
  affectableTasks: [],
  taskOfficers:[],
  taskOfficersLoaded: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case TASKS_DATA: {
      return {
        ...state,
        ['tasks' + action.unitId]: action.payload === undefined ? [] : action.payload,
        ['activeTasks' + action.unitId]: action.payload === undefined ? [] : action.payload.filter(n => n.isActive),
        filteredtasks: action.payload === undefined ? [] : action.payload,
        ['tasksLoaded' + action.unitId]: true,
        tasks: action.payload === undefined ? [] : action.payload,
        tasksLoaded: true,
      };
    }

    case FILTERED_TASKS_DATA: {
      return {
        ...state,
        filteredtasks: action.payload === undefined ? [] : action.payload,
      };
    }    
    case UPDATE_TASK_START:
    case DELETE_TASK_START: {
      return {
        ...state,
        updateSuccess: false,
        updating: true,
        updatedTaskId: action.payload,
        updatingTask: true
      };
    }

    case UPDATE_TASK_SUCCESS: {
      return {
        ...state,
        updateSuccess: true,
        updating: false,
        updatedTaskId: action.payload,
        updatingTask: false
      };
    }

    case TASK_RECORD: {
      return {
        ...state,
        taskId: action.payload.taskId,
        updatingTask: false
      };
    }

    case DELETE_TASK_SUCCESS: {
      return {
        ...state,
        taskId: 0,
        updatedTaskId: 0,
        updatingTask: false
      };
    }

    case DASHBOARD_FILTERS_CLEAR_SELECTEDTASK: {
      return {
        ...state,
        tasks: []
      };
    }

    case OFFICER_AFFECTABLE_TASKS_DATA : {
      return {
        ...state,
        affectableTasks : action.payload
      };
    }

    case TASK_OFFICERS_START : {
      return {
        ...state,
        ['taskOfficersLoaded' + action.taskId]: false,
        ['taskOfficers' + action.taskId]: []
      };
    }

    case TASK_OFFICERS_SUCCESS : {
      return {
        ...state,
        ['taskOfficers' + action.taskId]: action.payload === undefined ? [] : action.payload,
        ['taskOfficersLoaded' + action.taskId]: true
      };
    }

    default:
      return state;
  }
}
