import { 
    FETCH_PIVOTTABLEREPORTSDATA_DATA,
    UPDATE_PIVOTTABLEREPORTS_SUCCESS
} from "../constants/ActionTypes";

const INIT_STATE = {
    pivotTableReports: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
      case FETCH_PIVOTTABLEREPORTSDATA_DATA: {
        return {
          ...state,
          pivotTableReports: action.payload === undefined ? [] : action.payload,
        };
      }

      case UPDATE_PIVOTTABLEREPORTS_SUCCESS: {
        return {
          ...state,
          pivotTableReports: action.payload === undefined ? [] : action.payload,
        };
      }      
  
      default:
        return state;
    }
  }