import {     
    FETCH_ALL_ISSUES_START,
    ISSUES_DATA, 
    FILTERED_ISSUES_DATA, 
    UPDATE_ISSUE_START, 
    UPDATE_ISSUE_SUCCESS, 
    DELETE_ISSUE_START, 
    DELETE_ISSUE_SUCCESS, 
    SELECT_ISSUE
  } from "../constants/ActionTypes";
  
  const INIT_STATE = {
    issues: [],
    filteredIssues: [],
    updatedIssueId: 0,
    updatingIssue: false,
    issuesLoaded: false
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
      case FETCH_ALL_ISSUES_START: {
        return {
          ...state,
          ['issuesLoaded' + action.unitId]: false
        };        
      }
  
      case ISSUES_DATA: {
        return {
          ...state,
          issues: action.payload === undefined ? [] : action.payload,
          ['issues' + action.unitId]: action.payload === undefined ? [] : action.payload,
          ['activeIssues' + action.unitId]: action.payload === undefined ? [] : action.payload.filter(n => n.isActive),
          filteredIssues: action.payload === undefined ? [] : action.payload,
          ['issuesLoaded' + action.unitId]: true
        };
      }
  
      case FILTERED_ISSUES_DATA: {
        return {
          ...state,
          filteredIssues: action.payload === undefined ? [] : action.payload,
        };
      }      
  
      case UPDATE_ISSUE_START: {
        return {
          ...state,
          updateSuccess: false,
          updatedIssueId: action.payload,
          updatingIssue: true
        };
      }
      
      case UPDATE_ISSUE_SUCCESS: {
        return {
          ...state,
          updateSuccess: true,
          updatedIssueId: action.payload,
          updatingIssue: false
        };
      }  
      
      case DELETE_ISSUE_START: {
        return {
          ...state,
          updatingIssue: true
        };
      }
  
      case DELETE_ISSUE_SUCCESS: {
        return {
          ...state,
          updatingIssue: false
        };
      }    
  
      case SELECT_ISSUE: {
        return {
          ...state,
          selectedIssue: action.payload
        };      
      }
  
      default:
        return state;
    }
  }
  