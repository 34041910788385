/**
 * Get milliseconds from midnight January 1 1970 UTC to given ISO date
 * 
 * @param {string} isoDate date format ISO
 * @returns {number} milliseconds
 */
export function getMillisFromIsoDate(isoDate) {
    return new Date(isoDate).getTime();
};

/**
 * Get string "dd/MM/yyyy hh:mm aa" format from date
 * 
 * @param {Date} date datetime object
 * @returns {string} string format
 */
export function formatDatetimeToDDMMYYYY(date = new Date()) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    
    hours = hours % 12 || 12;
    const formattedHours = String(hours).padStart(2, '0');

    return `${day}/${month}/${year} ${formattedHours}:${minutes} ${ampm}`;
}

/** Get ISO string from date without timezone offset
 * 
 * @param {Date} date 
 * @returns ISO string
 */
export function getISOWithoutTimeOffset(date = new Date()) {
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0, -1);
}