import React, { useState, useEffect } from 'react';
import Nprogress from 'nprogress';
import ReactPlaceholder from 'react-placeholder';
import 'nprogress/nprogress.css';
import 'react-placeholder/lib/reactPlaceholder.css';
import CircularProgress from "../components/CircularProgress/index";

export default function asyncComponent(importComponent) {
  return function AsyncFunc(props) {
    const [Component, setComponent] = useState(null);

    useEffect(() => {
      let isMounted = true;
      Nprogress.start();
      
      importComponent().then(({ default: LoadedComponent }) => {
        Nprogress.done();
        if (isMounted) {
          setComponent(() => LoadedComponent);
        }
      });
      
      return () => {
        isMounted = false;
      };
    }, []);

    return (
      <ReactPlaceholder type="text" rows={7} ready={Component !== null}>
        {Component ? <Component {...props} /> : (
          <div className="loader-view" style={{ height: 'calc(100vh - 200px)' }}>
            <CircularProgress />
          </div>
        )}
      </ReactPlaceholder>
    );
  };
}