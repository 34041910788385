import {
    FETCH_START,
    FETCH_PIVOTTABLEREPORTSDATA_SUCCESS,
    FETCH_PIVOTTABLEREPORTSDATA_DATA,
    UPDATE_PIVOTTABLEREPORTS_START,
    UPDATE_PIVOTTABLEREPORTS_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    ERROR_OTHERS,
    FETCH_ERROR
} from "../constants/ActionTypes";
import axios from 'util/Api';

export const fetchPivotTableReports = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.get('/pivottablereports')
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_PIVOTTABLEREPORTSDATA_SUCCESS });
                    dispatch({ type: FETCH_PIVOTTABLEREPORTSDATA_DATA, payload: data.result.pivotTableReports });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const updateOrCreatePivotTableReports = (reports) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_PIVOTTABLEREPORTS_START });
        axios.post('/pivottablereports', reports)
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: UPDATE_PIVOTTABLEREPORTS_SUCCESS });
                    dispatch({ type: FETCH_PIVOTTABLEREPORTSDATA_DATA, payload: data.result.pivotTableReports });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};