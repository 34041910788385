import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import asyncComponent from "../../../util/asyncComponent";

const Dashboard = ({ match }) => (
    <div className="app-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/teamefficiency`} />
            <Route exact path={`${match.url}/teamefficiency`} component={asyncComponent(() => import('./routes/TeamEfficiency'))} />
            <Route exact path={`${match.url}/teamefficiencyofficers`} component={asyncComponent(() => import('./routes/TeamEfficiencyOfficers'))} />
            <Route exact path={`${match.url}/timeline`} component={asyncComponent(() => import('./routes/TimeLine'))} />
            <Route exact path={`${match.url}/volumetry`} component={asyncComponent(() => import('./routes/Volumetry'))} />
            <Route exact path={`${match.url}/distribution`} component={asyncComponent(() => import('./routes/Distribution/Tasks'))} />
            <Route exact path={`${match.url}/distribution/details`} component={asyncComponent(() => import('./routes/Distribution/TaskDetails'))} />
            <Route exact path={`${match.url}/helpdistribution`} component={asyncComponent(() => import('./routes/Distribution/Help'))} />
            <Route exact path={`${match.url}/helpdistribution/details`} component={asyncComponent(() => import('./routes/Distribution/HelpDetails'))} />
            <Route exact path={`${match.url}/andondistribution`} component={asyncComponent(() => import('./routes/Distribution/Andon'))} />
            <Route exact path={`${match.url}/andondistribution/details`} component={asyncComponent(() => import('./routes/Distribution/AndonDetails'))} />            
            <Route exact path={`${match.url}/taskdetails`} component={asyncComponent(() => import('./routes/Tasks/TaskDetails'))} />
            <Route exact path={`${match.url}/taskdetails/details`} component={asyncComponent(() => import('./routes/Tasks/TaskDetailsDetail'))} />
            <Route exact path={`${match.url}/taskdetails/detailsdate`} component={asyncComponent(() => import('./routes/Tasks/TaskDetailsDetailDate'))} />
            <Route exact path={`${match.url}/performanceaverage`} component={asyncComponent(() => import('./routes/Tasks/AveragePerformance'))} />
            <Route exact path={`${match.url}/performance/standardization`} component={asyncComponent(() => import('./routes/Tasks/StandardizationDetail'))} />
            <Route exact path={`${match.url}/realtime/tasks`} component={asyncComponent(() => import('./routes/RealTime/Tasks'))} />
            <Route exact path={`${match.url}/realtime/andon`} component={asyncComponent(() => import('./routes/RealTime/Andon'))} />
            <Route exact path={`${match.url}/realtime/limits`} component={asyncComponent(() => import('./routes/RealTime/Limits'))} />
            <Route exact path={`${match.url}/realtime/help`} component={asyncComponent(() => import('./routes/RealTime/Help'))} />
            <Route exact path={`${match.url}/standardization`} component={asyncComponent(() => import('./routes/Standardization'))} />
            <Route exact path={`${match.url}/pivottable`} component={asyncComponent(() => import('./routes/Tasks/PivotTable'))} />
            <Route exact path={`${match.url}/activitylist`} component={asyncComponent(() => import('./routes/Tasks/List'))} />
            <Route exact path={`${match.url}/activitylist/edit`} component={asyncComponent(() => import('./routes/Tasks/Edit'))} />
            <Route exact path={`${match.url}/activitylogs`} component={asyncComponent(() => import('./routes/Tasks/Traces/List'))} />
            <Route exact path={`${match.url}/realtime/issues`} component={asyncComponent(() => import('./routes/RealTime/Issues/List'))} />
            <Route exact path={`${match.url}/realtime/coachings`} component={asyncComponent(() => import('./routes/RealTime/Coachings'))} />
            <Route exact path={`${match.url}/issuesdistribution/distribution/bytask`} component={asyncComponent(() => import('./routes/Distribution/Issues/Tasks'))} />
            <Route exact path={`${match.url}/issuesdistribution/distribution/byissue`} component={asyncComponent(() => import('./routes/Distribution/Issues/Issues'))} />
            <Route exact path={`${match.url}/listing/limits`} component={asyncComponent(() => import('./routes/Listing/Limits'))} />
            <Route exact path={`${match.url}/listing/issues`} component={asyncComponent(() => import('./routes/Listing/Issues'))} />
            <Route exact path={`${match.url}/listing/coachings`} component={asyncComponent(() => import('./routes/Listing/Coachings'))} />
            <Route exact path={`${match.url}/coachingsdistribution/distribution/bycoaching`} component={asyncComponent(() => import('./routes/Distribution/Coaching/Coachings'))} />
            <Route exact path={`${match.url}/coachingsdistribution/distribution/byofficer`} component={asyncComponent(() => import('./routes/Distribution/Coaching/Officers'))} />
            <Route exact path={`${match.url}/issues/timeline`} component={asyncComponent(() => import('./routes/Evolution/Issues/Timeline'))} />
            <Route exact path={`${match.url}/issues/volumetry`} component={asyncComponent(() => import('./routes/Evolution/Issues/Volumetry'))} />
            <Route exact path={`${match.url}/coachings/timeline`} component={asyncComponent(() => import('./routes/Evolution/Coachings/Timeline'))} />
            <Route exact path={`${match.url}/coachings/volumetry`} component={asyncComponent(() => import('./routes/Evolution/Coachings/Volumetry'))} />
            <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
        </Switch>
    </div>
);

export default Dashboard;