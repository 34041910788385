import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import asyncComponent from "../../util/asyncComponent";
import Structure from "./structure";
import Settings from "./settings";
import Dashboard from "./dashboard";

const Routes = ({ match }) =>
  <Switch>
    <Route path={`${match.url}/structure`} component={Structure} />
    <Route path={`${match.url}/settings`} component={Settings} />
    <Route path={`${match.url}/dashboard`} component={Dashboard} />
    <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>        
  </Switch>;
//Test GIT

export default withRouter(Routes);

