import {
    ERROR_OTHERS,
    CLEAN_ERROR,
    SIGNOUT_USER_SUCCESS,
    SESSION_TIMED_OUT
} from "../constants/ActionTypes";

export const setError = (error) => {
        return (dispatch) => {
            console.log("error: ", error);
            if(error.response === undefined) {
                dispatch({ type: ERROR_OTHERS, payload: error });
            } else {
                if (error.response.status === 401) {                
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
            }
        }
};

export const cleanError = () => {
    return (dispatch) => {
        dispatch({ type: CLEAN_ERROR });
        dispatch({ type: SESSION_TIMED_OUT, payload: false });        
    }
};