import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import Common from './Common';
import Auth from './Auth';
import Companies from './Companies';
import Units from './Units';
import Officers from './Officers';
import Tasks from './Tasks';
import Errors from './Errors';
import Dashboard from './Dashboard';
import Attendances from './Attendances';
import PivotTableReports from './PivotTableReports';
import Issues from './Issues';
import Coachings from './Coachings';

export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  commonData: Common,
  auth: Auth,  
  companies: Companies,
  units: Units,
  officers: Officers,
  tasks: Tasks,
  dashboard: Dashboard,
  attendances: Attendances,
  pivotTableReports: PivotTableReports,
  errors: Errors,
  issues: Issues,
  coachings: Coachings,
});
