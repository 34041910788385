import { Link } from '@material-ui/core';
import React from 'react';
import IntlMessages from 'util/IntlMessages';

const Footer = () => {
  return (
    <footer className="app-footer">
      <span className="d-inline-block">Copyright Lean4-0.be &copy; 2020 - </span>
      <span className="d-inline-block">
        <Link
          href={process.env.REACT_APP_WEB_APP_URL}
          target='_blank'
          rel="noopener noreferrer"
        >
          <IntlMessages id="footer.openWebapp"/>
        </Link>
      </span>
    </footer>
  );
};

export default Footer;
