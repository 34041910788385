import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    OFFICERS_DATA,
    FILTERED_OFFICERS_DATA,
    UPDATE_OFFICER_START,
    UPDATE_OFFICER_SUCCESS,
    DELETE_OFFICER_START,
    DELETE_OFFICER_SUCCESS,
    OFFICER_RECORD,
    SELECT_OFFICER,
    DASHBOARD_FILTERS_CLEAR_SELECTEDOFFICER,
    SIGNOUT_USER_SUCCESS,
    ERROR_OTHERS,
    UNIT_OFFICERS_DATA,
    OFFICER_AFFECTED_TASK_DATA_START,
    OFFICER_AFFECTED_TASK_DATA_SUCCESS,
    DELETE_OFFICER_TASK_START,
    DELETE_OFFICER_TASK_SUCCESS,
    CREATE_OFFICER_TASK_START,
    CREATE_OFFICER_TASK_SUCCESS,
    OFFICERS_AFFECTED_TASK_FOR_UNIT_DATA_START,
    OFFICERS_AFFECTED_TASK_FOR_UNIT_DATA_SUCCESS,
    UPDATE_OFFICER_TASKS_START,
    UPDATE_OFFICER_TASKS_SUCCESS
} from "../constants/ActionTypes";
import axios from 'util/Api';

export const fetchOfficers = (defaultCompanyId) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.get('/officers', {
            params: {
                defaultCompanyId: defaultCompanyId
            }
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: OFFICERS_DATA, payload: data.result.officers });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const fetchUnitOfficers = (unitId) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.get('/officers', {
            params: {
                unitId: unitId
            }
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: UNIT_OFFICERS_DATA, payload: data.result.officers, unitId: unitId });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const sortData = (data, order, orderBy) => {
    return (dispatch) => {
        order === "desc"
            ? data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
            : data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
        dispatch({ type: OFFICERS_DATA, payload: data });
    }
};

export const filterData = (data, filterText) => {
    return (dispatch) => {
        var filteredData = data.filter(function(text) {
                if (filterText.length > 0) {
                    return ((text.lastName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0) || text.firstName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0);
                } else {
                    return true;
                }
        });
        dispatch({ type: FILTERED_OFFICERS_DATA, payload: filteredData });
    }
};

export const updateOrCreateOfficer = (officer, refreshSelected) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_OFFICER_START });
        var retValue = null;

        axios.post('/officers', {
            ...officer
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: UPDATE_OFFICER_SUCCESS });
                    dispatch({ type: OFFICER_RECORD, payload: data.result.officer });
                    retValue = data.result.officer;
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            })
            .then(() => {
                if (refreshSelected) {
                    dispatch({ type: SELECT_OFFICER, payload: retValue });
                }
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const selectOfficer = (selectOfficer) => {
    return (dispatch) => {
        dispatch({ type: SELECT_OFFICER, payload: selectOfficer });
    }
}

export const deleteOfficer = (officer, defaultCompany) => {
    return (dispatch) => {
        dispatch({ type: DELETE_OFFICER_START });
        axios.delete('/officers', {
            data: {...officer}
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: DELETE_OFFICER_SUCCESS });
                    dispatch(fetchOfficers(defaultCompany.companyId));
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const deleteUnitOfficer = (officer) => {
    return (dispatch) => {
        dispatch({ type: DELETE_OFFICER_START });
        axios.delete('/unitOfficers', {
            data: {...officer}
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: DELETE_OFFICER_SUCCESS });
                    dispatch(fetchOfficers(officer.companyId));
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                console.log("Error****:", error);
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const clearDashboardFiltersSelectedOfficer = () => {
    return (dispatch) => {
        dispatch({ type: DASHBOARD_FILTERS_CLEAR_SELECTEDOFFICER });
    }
}

export const deleteOfficerAffectedTask = (officerId,selectedTaskId) => {
    return (dispatch) => {
        dispatch({ type: DELETE_OFFICER_TASK_START });
        axios.delete('/officerAffectedTasks', {
            params : {
                officerId : officerId,
                taskId : selectedTaskId
            }
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: DELETE_OFFICER_TASK_SUCCESS });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                console.log("Error****:", error);
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
}

export const addOfficerAffectedTask = (officerId,taskId) => {
    return (dispatch) => {
        dispatch({ type: CREATE_OFFICER_TASK_START });
        var link = {
            officerId : officerId,
            taskId : taskId
        }
        axios.post('/officerAffectedTasks', {
            ...link
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: CREATE_OFFICER_TASK_SUCCESS });                    
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                console.log("Error****:", error);
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
}

export const fetchOfficerAssociatedTasks = (officerId) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        dispatch({ type: OFFICER_AFFECTED_TASK_DATA_START})
        axios.get('/officerAffectedTasks', {
            params: {
                officerId: officerId
            }
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: OFFICER_AFFECTED_TASK_DATA_SUCCESS, payload: data.result.tasks });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
}

export const fetchOfficerAffectedTasksForUnit = (unitId) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        dispatch({ type: OFFICERS_AFFECTED_TASK_FOR_UNIT_DATA_START, unitId : unitId})
        axios.get('/officerAffectedTasksForUnit', {
            params: {
                unitId: unitId
            }
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: OFFICERS_AFFECTED_TASK_FOR_UNIT_DATA_SUCCESS, payload: data.result.officersTasks, unitId : unitId});
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
}

export const updateOfficerAffectedTasks = (listOfChanges, unitId) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_OFFICER_TASKS_START, unitId : unitId});
        let results=[];
        listOfChanges.forEach(link => {
            link.checked ? 
                axios.post('/officerAffectedTasks', {
                    ...link
                }).then(({ data }) => {
                    if (data.result) {
                        results.push(data.result);

                        dispatch({ type: CREATE_OFFICER_TASK_SUCCESS });
                        if (results.length === listOfChanges.length)
                            {
                                dispatch({ type: UPDATE_OFFICER_TASKS_SUCCESS, unitId : unitId });
                            }                  
                    } else {
                        console.log("payload: data.error", data.error);
                        dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                    }
                }).catch(function (error) {
                    console.log("error", error);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("user");
                        dispatch({type: SIGNOUT_USER_SUCCESS});
                    } else {
                        dispatch({ type: ERROR_OTHERS, payload: error });
                    }
                    dispatch({ type: FETCH_ERROR, payload: error.message });
                    console.log("Error****:", error.message);
                })
                :
                axios.delete('/officerAffectedTasks', {
                    params : {
                        officerId : link.officerId,
                        taskId : link.taskId
                    }
                }).then(({ data }) => {
                    if (data.result) {
                        results.push(data.result);
                        console.log("Deleting link..." + data.result);
                        dispatch({ type: DELETE_OFFICER_TASK_SUCCESS });
                        if (results.length === listOfChanges.length)
                            {
                                dispatch({ type: UPDATE_OFFICER_TASKS_SUCCESS, unitId : unitId });
                            } 
                    } else {
                        console.log("payload: data.error", data.error);
                        dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                    }
                }).catch(function (error) {
                    console.log("error", error);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("user");
                        dispatch({type: SIGNOUT_USER_SUCCESS});
                    } else {
                        dispatch({ type: ERROR_OTHERS, payload: error });
                    }
                    dispatch({ type: FETCH_ERROR, payload: error.message });
                    console.log("Error****:", error.message);
                });
        }); 
                       
    }
}
